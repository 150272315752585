
/*************************
* SEZNAMY
*************************/

/*************************
* MIXINY PRO ODRÁŽKY
*************************/

@mixin bullet($bg: #BCBCBC, $border: $bg, $top: -.08em) {
	&::before {
		@extend %border-box;
		position: relative;
		top: $top;
		margin-right: 7px;
		display: inline-block;
		content: encode-svg('<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="7px" height="7px" viewBox="0 0 7 7"><path fill="#{$bg}" stroke="#{$border}" stroke-miterlimit="10" d="M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z"/></svg>');
		vertical-align: middle;
		line-height: 0;
		overflow: hidden;

		@content;
	}
}

@mixin no-bullet {
	&::before {
		content: normal !important;
	}
}


@mixin dlazdice-ul {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -13px 35px -13px;
	list-style-type: none;
	padding: 0;
	text-align: center;

	body.leftpanel & li {
		@include grid(740px 2 0, 800px 1, 900px 2, 1024px 3);
	}

	#titul & li, 
	body.noleftpanel & li {
		@include grid(650px 2 0, 880px 3, 1024px 4);
	}

	li {
		box-sizing: border-box;
		padding: 12px 13px;
		background-color: #ffffff;
		border: 2px transparent solid;
		background-clip: padding-box;
		margin-top: 0;
		margin-bottom: 0;
		text-align: left;
		position: relative;
		@include grid(550px 2 0, 800px 3, 1024px 4);

		strong + div {
			font-style: normal;
		}

		> strong:first-child {
			font-weight: normal;

			a {
				font-weight: bold;
				font-family: $font;
				font-size: rem(23px);
				display: block;
				padding: 12px 28px;

				&::before {
					content: "";
					@include fill;
				}
			}

			@include link {
				border-top: 2px #99cdcc solid;
				color: #008381;
				text-decoration: none;
				background-color: #f6f6f6;
			}

			@include link-over {
				background-color: #008381;
				border-top-color: #008381;
				color: #ffffff;
			}

			// @include link-over {
			// 	text-decoration: underline;
			// }
		}
	}
}


/************************/

li {
	.odkazy .ui & li li,
	.aktuality2 &.bezobr li li,
	.utvary .ui &.o li li,
	.kategorie .ui & li li,
	.utvary .ui & li li,
	.galerie .ui & li li {
		@include bullet;
	}

	&.u,
	&.typsouboru {
		@include no-bullet;
		list-style-type: none !important;
	}

	.odkazy ol &::before {
		@include no-bullet;
	}
}

dl dt {
	font-size: rem(19px);
	color: $list-top-level-link-color;
	line-height: 1.1;
}

dl.kontakty .utvary {
	padding-top: 4px;
}

.ui {
	li > strong:first-child {
		font-family: $font2;
		font-size: rem(21px);

		@include link {
			color: $list-top-level-link-color;
		}

		.utvary &,
		.bodkazy &,
		.souvisejiciodkazy & {
			font-family: $sans-serif;
			font-size: rem(16px);
			font-weight: bold;

			@include link {
				//color: $color-base-link;
				color: $barR;
			}
		}

		img {
			font-size: rem(14px);
		}
	}

	li li > strong:first-child {
		.utvary &,
		.bodkazy &,
		.souvisejiciodkazy & {
			font-weight: normal;
		}
	}

	ul li > strong:first-child {
		font-family: $sans-serif;
		font-size: rem(16px);

		@include link {
			color: $color-base-link;
		}
	}

	li {
		clear: none;
		padding: 0;
		margin: 0 0 5px 0;

		strong {
			font-weight: normal;
			line-height: 1.2;

			dl.kontakty .utvary &,
			#stromutvaru .utvary &,
			.odkazy & {
				+ div {
					padding: 0;
					color: #666666;
					line-height: 1.4;
					font-style: italic;
				}
			}

			dl.kontakty .utvary & + div {
				margin-bottom: 10px;
			}
		}

		.ui {
			padding-top: 0;
			padding-bottom: 10px;

			.ui {
				padding-bottom: 0;
				
				.ui {
					padding-left: 18px;
				}
			}
		}

		li {
			margin: 5px 0;

			&::after {
				content: normal;
			}
		}
	}

	ol li {
		padding-left: 0;
	}

	div {
		font-weight: normal;
		margin-bottom: 2px;
		margin-top: 6px;
		padding-top: 0;
		padding-bottom: 0;
	}
}


dl.kontakty .utvary {
	ul.ui {
		margin-left: 0;
		margin-bottom: 15px;
	}

	li {
		strong {
			font-size: rem(15px);
			font-family: $sans-serif;

			@include link {
				color: $color-base-link;
			}
		}
	}
}


/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/

#isvs .hodnota {
	@extend .b2;

	li {
		@include no-bullet;
	}
}


/*********************
* VÝPISY DOKUMENTŮ
*********************/

.dok {
	ul {
		@extend %reset-list;
	}

	li {
		display: block;
		margin: 20px 0;
		padding: 0;

		&:first-child {
			margin-top: 8px;
		}
	}

	.n5-akce-typ,
	.dok-doplnek,
	.ktg,
	strong + span,
	.vd-priloha {
		font-size: rem(15px);
		color: #444444;

		@include link {
			color: #444444;
		}
	}

	.vd-priloha {
		font-family: $sans-serif;
	}

	strong {
		font-weight: normal;

		img {
			margin: 3px 20px 6px 0;
			float: left;
		}

		a {
			font-size: rem(22px);
			font-family: $font;
			color: $document-link-color;
			line-height: 1.1;
		}
	}
}

#zpravodajstvi {
	ul.inline {
		padding: 0;
		margin: 20px 0;
	}
}


#kalendarAkci .dok {
	li.u {
		padding-left: 150px;

		strong img {
			margin-left: -150px;
		}
	}
}


/** DOKUMENTY NA TITULCE **/

#pozicovani {
	padding-bottom: 20px;

	.poz {
		@include bp(">= 650px") {
			margin-bottom: 25px;
		}
	}

	.cards {
		ul {
			@extend %sf;
			@include flex-base;
			@include flex-wrap(wrap);
			margin-top: 48px;
		}

		li {
			@extend %card;
			padding: 28px 32px 75px;
			position: relative;

			&::after{
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				width: 9px;
				transition: background  1s, height .5s;
				height: 0;
				background: $barB;
			}

			&:hover::after{
				height: 100%;
				background: $barR;
			}
		}

		.dok-datum{
			font-family: $font;
			font-size: rem(20px);
			font-weight: 600;
			color: #676767;
		}

		strong a {
			color: $barB;

			&:hover{
				color: $barR;
			}
		}

		.dok-nazev{
			font-family: $font;
			font-size: rem(25px);
			font-weight: 600;

			@include bp("< 800px"){
				font-size: rem(22px);
			}
		}

		.popis{
			font-family: $font2;
			font-size: rem(17px);
			color: #454545;
		}

		.dok-slozka{
			position: absolute;
			left: 0;
			right: 0;
			bottom: 18px;
			padding: 0 32px;
			font-family: $font2;
			font-size: rem(15px);
			color: #393939;
		}

		@include bp("< 800px"){
			li{
				padding: 24px 20px 80px;
			}

			.dok-slozka{
				padding: 0 20px;
			}
		}

		@include bp("< 650px"){
			ul{
				margin-top: 24px;
			}	
		}

		@include bp("< 600px"){	
			li{
				@include limit-items( 4 );
			}
		}	
	}

	.cards-horizontal {
		ul {
			@extend %sf;
			@include flex-base;
			@include flex-wrap(wrap);
		}

		li {
			@extend %card-horizontal;
		}
	}

	@include bp(">= 650px") {
		.skryty {
			display: none;
		}
	}
	
	.obaldalsi{
		text-align: center;	

		@include bp("< 650px"){
			margin-bottom: 0;
		}
		
		.dalsi{
			display: inline-block;
			position: relative;
			width: 12.3em;
			height: 2.85em;
			font-size: rem(20px);
			background-color: $barR;
			text-align: center;
			padding: 0;
			border-radius: 3px;
			border: 0;


			&::after{
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				transition: height 0.5s;
				height: 0;
				background-color: #b90414;
				z-index: 2;
				border-radius: 3px;
			}
				
			&:hover::after{
				height: 100%;
			}	
		
			a{
				font-size: rem(20px);
				text-transform: uppercase;
				position: absolute;
				left: 0; right: 0; top: 0; bottom: 0;
				z-index: 3;
				color: white;
				font-family: $font;
				font-weight: 600;
				padding: 15px;
				box-sizing: border-box; 
			}
		}
	}
	
}


/*******************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ	
*******************************/

.obrodkazy {
	.ui {
		@extend %reset-list;
	}

	li {
		@include no-bullet;
		@extend %reset;
		display: block;
		margin-bottom: 13px;
		background-image: none;
		position: relative;
	}

	a { 
		font-size: rem(20px);
		font-family: $sans-serif;
		background-repeat: no-repeat;
		background-position: 0 50%;
		padding-left: 65px;
		display: table;
		height: 1em;
		min-height: 48px;
	}

	.mtext {
		display: table-cell;
		vertical-align: middle;
	}

	strong {
		font-weight: normal;
	}

	@include link {
		text-decoration: none;
	}

	@include link-over {
		text-decoration: underline;
	}
}


/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/

#vypisakci {
	.dok {
		margin: 30px 0 18px;

		li {
			display: table;
			padding: 20px 0;
			margin: 0;
			@include grid (700px 2 4%);
			border-bottom: 1px solid #f4c744;

			&:nth-child(n+3){
				border: 0;
			}

			@include bp("< 700px"){
				&:nth-child(3){
					border-bottom: 1px solid #f4c744;
				}
			}

			// &:hover .datum::after{
			// 	height: 100%;
			// }
		
			strong {
				@include link {
					color: $event-link-color;
					font-size: rem(25px);
					font-weight: 600;
					display: inline-block;
					padding: 12px 0 4px;
					
					@include bp("<= 600px"){
						font-size: rem(22px);
					}
				}
			}
		}

		.datum,
		.prazdnyDatum {
			display: table-cell;
			vertical-align: top;
			width: 6em;
			text-align: center;
			margin: .26em 0;
			font-family: $font;
			position: relative;
		}

		.den,
		.mesic {
			display: block;
			line-height: 1.2;
		}

		.den {
			font-size: rem($event-day-size);
			font-weight: bold;
			background: $event-day-background;
			color: $event-day-text;
			padding: .1em 0;
			border-radius: 3px 3px 0 0;
			position: relative;
			z-index: 3;
		}

		.mesic {
			font-size: rem($event-month-size);
			background: transparent;
			color: $event-month-text;
			text-transform: lowercase;
			padding: .5em 0;
			border-radius: 0 0 3px 3px;
			position: relative;
			z-index: 3;
			border-top: 2px solid $event-month-background;
		}

		.datum{
			background-color: $event-month-background;

			// &::after{
			// 	content: "";
			// 	position: absolute;
			// 	left: 0; right: 0;
			// 	top: 0;
			// 	height: 0;
			// 	transition: height 1s;
			// 	background-color: $barZ;
			// 	border-radius: 3px;
			// }
		}

		.denPredlozka,
		.rok, 
		.den .tecka {
			position: absolute;
			left: -9999px;
			right: 9990px;
		}

		.prazdnyDatum {
			background: transparent;
		}

		.obsahAkce {
			display: table-cell;
			padding-left: 24px;
		}
	}
}



/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/

.titulodkazy2{
	ul,li{
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	ul{
		padding: 46px 0 0;
	}

	li{
		padding: 0 0 10px 3.2%;
	}

	@include bp("< 650px"){
		ul{
			padding: 22px 0 16px;
		}
	}

	@include bp("<= 600px"){
		li a{
			font-size: rem(20px)!important;
		}	
	}
}

#obalTemata{
	width: 100%;
}

.titulodkazy {
	h2.cvi{
		font-size: rem(30px);
		font-weight: 600;
		color: #2c2c2c;
		background: url(images/sipkaTemata.png) no-repeat center bottom;
		padding: 0 0 13px;
		float: left;
		margin: -49px 0 0 ratio(1280px, 34px); 
		position: relative;
		z-index: 2;

		@include bp("< 1024px"){
			display: none;
		}

		span{
			display: block;
			padding: 11px 32px 12px;
			background-color: $barZ;
		}
	}

	.ui {
		clear: left;
		@extend %reset-list;
		padding: 12px 0 32px;
		display: flex;
		flex-wrap: wrap;
	}

	li {
		@include no-bullet;
		@include grid(500px 2 4, 700px 3);
		background-image: none;
		margin-top: 0;
		margin-bottom: 0;
		padding: 18px ratio(1280px, 34px);
		box-sizing: border-box;
		position: relative;
		overflow: hidden;
		border-top: 2px solid white;

		@include bp("> 500px"){
			min-height: 116px;
		}

		&::before{
			content: "" !important;
			position: absolute;
			background-color: $barZ;
			height: 0;
			transition: height 0.5s;
			//transition-delay: 0.5s;
			z-index: 1;
			left: 0; top: 0; right: 0;
		}

		&:hover{
			border-color: $barZ;

			a, div{
				color: #1f1f1f!important;
			}
			
			&::before{
				height: 100%;
			}	
		}

		a {
			font-family: $font;
			font-size: rem(25px);
			color: #b30717;
			text-transform: uppercase;
			font-weight: 600;
			text-decoration: none;
			position: relative;
			z-index: 2;

			@include bp("> 700px", "< 900px"){
				font-size: rem(22px);
			}

			@include bp(">= 500px", "<= 600px"){
				font-size: rem(22px);
			}
		}

		div {
			margin: 4px 0 0 0;
			font-family: $font2;
			font-size: rem(17px);
			color: #4f4f4f;
			position: relative;
			z-index: 2;
		}
	}

	strong {
		font-weight: normal;
	}
}


li.urd-line {
	position: relative;

	strong a::after {
		@include fill;
		content: "";
	}
}
