
/*****************************
* FORMULÁŘE
*****************************/

/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/

%hiding-label {
	line-height: 1.2;
	text-align: left;
	padding: 3px 2px;
	display: block;

	&.label-skryty {
		padding: 0 2px;
		position: relative;
		
		&::after {
			@extend %fill;
			content: "";
			z-index: 2;
		}
	}
}

/****************************/


/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/

.format {
	background-color: #ffffff;
	color: #000000;
	height: $format-height;
	border-radius: 0;
	line-height: ( 17 / 14 );
	font-size: rem(15px);

	&:-ms-input-placeholder {
		color: inherit !important;
	}

	&::-ms-input-placeholder {
		color: inherit !important;
	}

	&::placeholder {
		color: inherit !important;
	}

	&:focus {
		border-color: $format-border-focus;
	}
}

.format,
fieldset {
	border: 1px $format-border solid;
}

:not(.fvlevo) > .fvyraz #hv {
	font-size: rem(19px);
	height: $format-main-height;
	border-width: 2px;
}

textarea.format {
	min-height: $format-height * 2;
}

select.format[multiple] {
	height: $format-height * 3;
}

.btn {
	border: $btn-border;
	color: $btn-color;
	height: $btn-height;
}

.fbtn .btn {
	padding: 0 2em;
	font-size: rem(16px);
}

.fbtn div, #fprihl .fbtn{
	position: relative;
	background-color: $btn-background;
	text-align: center;

	.btn{
		position: relative;
		padding: 5px 36px;
		z-index: 2;
		display: block;
		color: #2a2a2a;
		font-family: $font;
		font-size: rem(20px);
		font-weight: 600;

		&:hover{
			text-decoration: underline;
		}
	}

	&::before{
		position: absolute;
		z-index: 1;
		content: "";
		top: 0;
		right: 0;
		left: 0;
		background-color: #F4C744;
		transition: height 0.5s;
		height: 0;
	}

	&:hover::before{
		height: 100%;
	}
}



/************************************************/

.fkont {
	margin-top: 20px;

	form {
		padding: $form-padding;
	}

	:not(.fvlevo) > .fvyraz label[for="hv"] {
		font-size: rem(16px);
	}
}

.nizky {
	@include bp("> 580px"){
		.fbtn, .fvpravo {
   			width: 9em;
   		}	
	}

	form{
		@include bp("<= 580px"){
			padding-bottom: 60px;
		}	
	}
}	

.formular,
.fkont form,
.nastaveni {
	background-color: $form-background;
	color: $form-text;

	@include link {
		color: $form-link;
	}
}

//zjistit, kde to vůbec je kromě diskuze
.fcesta,
#{selector-link-all(".fcesta a")} {
	background-color: $th-background;
	color: $th-text;
}


/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/

.titulformular {
	label {
		color: #eeeeea;
		font-size: rem(16px);
		font-family: $font2;

		@extend %hiding-label;
	}

	.format {
		margin: 2px 0 16px;
		padding: 8px 15px;
		width: 100%;
		border-color: #ffffff;
		background: {
			repeat: no-repeat;
			position: 0 0;
		}
		height: 41px;
	}

	img {
		position: absolute;
		right: 0;
		top: 0;
	}

	.opiste {
		position: relative;
	}

	.captchaformat {
		padding-right: 90px;
	}

	.fbtn,
	.btn {
		clear: none;
		margin: 0;
		box-sizing: border-box;	
	}

	.fbtn {
		width: 7em;
		margin: 14px auto 0;
		position: relative;
		background-color: $barZ;
		font-size: rem(20px);
		border-radius: 3px;

		&::before{
			position: absolute;
			border-radius: 3px;
			z-index: 1;
			content: "";
			top: 0; right: 0; left: 0;
			background-color: #F4C744;
			transition: height 0.5s;
			height: 0;
			border-radius: 3px;
		}

		&:hover::before{
			height: 100%;
		}
	}
	
	.btn {
		padding: 9px 16px 8px;
		width: 100%;
		position: relative;
		z-index: 3;
		color: #2a2a2a;
		font-family: $font;
		font-size: rem(20px);
		font-weight: 600;
		background-color: transparent;
		border: 0;
		text-transform: uppercase;
		height:43px;

		&:hover{
			text-decoration: underline;
		}
	}
	
	p {
		padding: 0 0 24px 0;
		margin: 0;
		font-family: $font2;
		font-size: rem(17px);
		color: #eeeeea;
	}

	.bezny.gdprsouhlas{
		margin-top: 0;
	}
}


/***************
*	HLEDÁNÍ
***************/

#hledani {
	overflow: hidden;
	box-sizing: border-box;
	width: 100%;
	padding: 0 3%;
	position: relative;

	@include bp("> 500px") {
		top: 8px;
	}

	@include bp("> 600px") {
		top: 0;
		float: right;
		padding-left: 0;
		width: 190px;
	}

	@include bp(">= 750px") {
		width: 292px;
		padding-right: 2.2%;
	}

	@include bp(">= 850px") {
		width: 210px;
		padding-right: 0;
	}

	@include bp(">= 1000px") {
		padding-bottom: 16px;
	}

	@include bp("> 1150px") {
		width: 200px;
	}

	.pole {
		position: relative;
		z-index: 5;
	}
	label {
		@extend %hiding-label;

		&.label-skryty::after {
			background: transparent url(images/hl_prekryti.svg) repeat 0 0;
		}
	}

	input:focus {
		outline: 0;
	}
	
	.format {
		width: 100%;
		box-sizing: border-box;
		margin: 0;
		height: 42px;
		border-radius: 3px;
		border: 1px solid $barG;
		color: #000000;
		padding: 4px 50px 4px 10px;
		background: {
			repeat: no-repeat;
			position: 0 50%;
		}
	}

	.btn {
		@extend %reset;
		border: 0;
		position: absolute;
		z-index: 2;
		top: 1px;
		right: -2px;
		height: auto;
		border-radius: 0;
		color: #ffffff;
		background-color: $barG;
	}
}
