
/*****************************************
* MIXINY A PROMĚNNÉ PRO TABULKOVÉ MENU
*****************************************/

$menu-spacing: 10px;
$menu-colors: #212922 #294936 #3e6259 #5b8266;


// @mixin make-spacing( $amount ) {
// 	margin-left: -#{$amount};
// 	margin-right: -#{$amount};
// }

// @mixin make-row( $spacing ) {
// 	width: 100%;
// 	display: table;
// 	table-layout: fixed;
// 	border-collapse: separate;
// 	border-spacing: $menu-spacing 0;
// }

// @mixin make-columns {
// 	margin: 0;
// 	padding: 0;
// 	display: table-cell;
// 	list-style-type: none;
// }

/****************************************/


/******************************
* HORIZONTÁLNÍ MENU A VÝBĚRY
******************************/

#obalmenu1 {
	position: relative;
	z-index: 2;
	clear: both;
	background-color: $barG;
	border-top: 1px solid #959595;
	border-bottom: 1px solid #dbd7d4;

	.centrovany{
		width: 100%;
	}
}

#menu1 {
	ul {
		@extend %reset-list;
		text-align: center;
		margin: 14px 0; 
	}

	li {
		font-size: rem(25px);
		line-height: 1.1;
		display: inline-block;
		font-weight: 600;
		margin: 6px 0;
		padding: 0 12px 0 8px;
		border-right: 1px solid $barZ;

		&:last-child{
			border: 0;
		} 

		&.akt {
			@include link {
				color: $barZ;
			}
		}

		a {
			display: block;
			font-family: $font;
			padding: 14px 16px;

			@include min(600px){
				padding: 5px;
			}

			@include min(1100px){
				padding: 0 20px;
			}
		}

		@include link {
			text-decoration: none;
			color: #ffffff;
			transition: color 0.5s;
		}

		@include link-over {
			color: $barZ;
		}

		@include link-all("#osmakth") {
			cursor: text;
			text-decoration: none;
		}
	}
	
	@include bp("< 1100px"){
		li {
			font-size: rem(22px);

			a{
			}
		}	
	}

	
	@include bp("< 800px"){
		li {
			font-size: rem(19px);
		}
	}

	@include bp("< 600px"){
		ul{
			margin: 0;
		}

		li {
			margin: 0;
			font-size: rem(22px);
			display: block;
			border-right: 0;
			border-bottom: 1px solid #DBD7D4;
		}
	}
}

// @include bp("> 500px", "<= 800px") {
// 	#menu1 {
// 		ul {
// 			display: flex;
// 			flex-wrap: wrap;
// 		}

// 		li {
// 			box-sizing: border-box;
// 			@include grid-column(2, 2%);
// 			float: left;
// 			margin-bottom: 15px;
// 		}
// 	}
// }

// @include bp("> 800px") {
// 	#menu1,
// 	#vybrane {
// 		@include make-spacing( $menu-spacing );
// 	}

// 	#menu1 ul,
// 	#vybrane .bg {
// 		@include make-row( $menu-spacing );
// 	}

// 	#menu1 li,
// 	#vybrane [class*="sekce"] {
// 		@include make-columns;
// 	}
// }

// @for $i from 1 through length($menu-colors) {
// 	#p#{$i},
// 	#vybrane .sekce0#{$i} {
// 		background: nth($menu-colors, $i);
// 	}
// }

/*************************
* VÝBĚRY MENU
*************************/

// #vybrane {
// 	display: none;

// 	@include bp("> 800px") {
// 		display: block;
// 	}

// 	.bg {
// 		@include link {
// 			color: #ffffff;
// 			text-decoration: none;
// 		}
		
// 		@include link-over {
// 			text-decoration: underline;
// 		}
// 	}

// 	ul {
// 		list-style-type: none;
// 		margin: 0;
// 		padding: 15px 22px 10px 22px;
		
// 		li {
// 			padding: 0;
// 			display: inline;
// 		}

// 		.pam {
// 			font-size: rem(11px);
// 			border-left: 1px #ffffff solid;
// 			margin: 0 0px 0 9px;
// 			padding: 0;
// 			vertical-align: middle;
// 			position: relative;
// 			top: -1px;
// 		}

// 		.pampv .pam {
// 			display: none;
// 		}

// 		@include link(".pamdalsi a") {
// 			text-decoration: underline;
// 		}
// 	}
// }


// MENU DALSI UROVNE
// -----------------

#stranka.noleftpanel{
	#obalcelek #celek.centrovany{
		max-width: 1234px;
		margin: 0 auto;
		width: 92%;
	}

	#telo{
		#stred{
			direction: ltr;
			display: block;
			width: 100%;
			float: none;
			clear: both;
			margin: 0 auto;
			padding: 0!important;

			#zahlavi h2.cvi{
				margin: 0;
				padding: 42px 0 28px;
			}
		}
	}	

	#menu-start {
		display:none;
	}	

	.mapa-stranek-2016{
		.odkazy.souvisejici ul.ui{
			margin: 0 0 44px;

			li{
				@include grid( 400px 2 5%, 700px 3 3.8%, 1100px 4);

				strong a{
					font-size: rem(24px);
				}

				div{
					font-size: rem(16px);
				}	
			}
		}		
	}
}

#osnova *{
	font-style: normal;
}

.mapa-stranek-2016{
	h3.souvisejiciodkazy{
		display: none;
	}

	.editor{
		margin-top: 30px;
		max-width: 100%;
	}

	.odkazy.souvisejici ul.ui{
		display: flex;
		flex-wrap: wrap;
		margin: 0;
		padding: 0;
		list-style-type: none;

		li{
			box-sizing: border-box;
			margin-top: 6px;
			margin-bottom: 30px;
			@include grid( 400px 2 5%, 1100px 3 3.8%);
			padding: 10px 0 0;
			border-top: 3px solid #ebebeb;
			position: relative;

			&:hover{
				border-color: $barZ;
			}

			strong a{
				font-size: rem(20px);
				font-family: $font;
				color: $barR;
				text-decoration: none;
				font-weight: 600;

				&::before{
					content: "";
					position: absolute;
					left: 0; top: 0; right: 0; bottom: 0;
				}
			}

			div{
				color: #4f4f4f;
				font-family: $font2;
				font-size: rem(15px);
				font-weight: 300;
				margin-top: 8px;
			}

			&:last-child{
				margin-bottom: 42px;
			}
		}
	}

	.pataprvku, .popis{
		margin: 30px 0;
	    box-sizing: border-box;
	    font-size: rem(17px);
	    font-family: $font2;
		padding: 0;
		max-width: 842px; 
		color: #4f4f4f;
	}

	.pataprvku{
		margin: 0;
		border-top: 7px solid #eff0eb;
		padding: 30px 0 40px;
	}

	@include bp("< 1000px"){
		ul.ui li strong a{
			font-size: rem(22px) !important;
		}
	}

	@include bp("< 600px"){
		ul.ui li strong a {
			font-size: rem(20px) !important;
		}
	}		
}


/*********************
* MENU VE SLOUPCI
*********************/

#menu ul {
	@extend %reset-list;
}

.menu-controls,
#menu-start {
	display: none;
}

.nadmenu,
#nadmenu {
	display: none;
}


/**********************
* MOBILNÍ MENU
**********************/

#menu {
	background-color: $menu-bg;
	padding: 20px;

	> ul {

		a {
			padding: 5px 20px;
			margin-left: -20px;
			display: block;
		}

		@include link {
			color: $menu-link-color;
		}

		@include link-all("#osmakt") {
			background-color: $menu-active-bg;
			color: $menu-active-text;
			text-decoration: none;
		}
	}

	.akt a{
		color: $barZ!important;
		text-decoration: none;
	}

	ul {
		padding-left: 20px;
	}
}


@include bp("<= #{$menu-tablet-size}") {
	#menu-start {
		display: block;
		clear: both;
		text-align: center;

		a {
			background: $menu-trigger-bg;
			display: block;
			padding: 15px 0;

			&::before {
				display: inline-block;
				vertical-align: middle;
				margin: -.15em .75em 0 0;
				content: encode-svg('<svg xmlns="http://www.w3.org/2000/svg" width="30" height="22" viewBox="0 0 30 22"><path fill="#{$menu-trigger-text}" fill-rule="evenodd" clip-rule="evenodd" d="M29 22h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1z"/></svg>');
			}
		}

		@include link {
			color: $menu-trigger-text;
			text-decoration: none;
		}
	}

	.no-scroll {
		height: 100%;
		touch-action: none;
		overflow: hidden;
		pointer-events: none;
		-webkit-overflow-scrolling: auto;

		body {
			height: 100%;
			touch-action: none;
			overflow: hidden;

			// > {
			// 	div {
			// 		height: 0;
			// 		touch-action: none;
			// 		overflow: hidden;
			// 	}
			// }
		}
	}

	#menu.menu-initialised {
		pointer-events: auto;
		box-sizing: border-box;
		z-index: 200;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		height: 100%;
		max-width: 400px;
		height: 100vh;
		padding: 70px 20px 20px 0;
		transition: transform .3s ease-out;
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
		opacity: 0;
		-webkit-transition: -webkit-transform .3s ease-out;

		[data-env="Safari"] & {
			max-width: 100%;
		}

		&.menu-active {
			-webkit-transform: translateX(0);
			transform: translateX(0);
			opacity: 1;
		}

		> ul {
			// position: relative;
			box-sizing: border-box;
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 0 0 0 20px;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			z-index: 101;

			> li {
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 80px;
				}
			}
			// ul {
			// 	padding: 0 0 0 20px;
			// }
		}

		ul {
			list-style-type: none;
		}

		.menu-controls {
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			display: block;
			height: 50px;
			z-index: 100;
			//width: 100%;
			background-color: $menu-topbar-bg;
			color: $menu-topbar-text;
			//display: table;

			.menu-control-back, 
			.menu-control-title {
				display: block;
			}

			.menu-control-back {
				position: absolute;
				width: 50px;
				height: 50px;
				right: 0;
				top: 0;
				background: {
					image: encode-svg('<svg width="22" height="22" xmlns="http://www.w3.org/2000/svg"><path fill="#{$menu-topbar-text}" fill-rule="evenodd" d="M13.569 10.994l8.162 8.165c.355.358.355.936 0 1.294l-1.28 1.279c-.357.358-.935.358-1.293 0l-8.16-8.166-8.162 8.166c-.357.358-.935.358-1.292 0l-1.278-1.279c-.357-.358-.357-.936 0-1.294l8.162-8.165-8.152-8.154c-.357-.357-.357-.935 0-1.292l1.279-1.28c.356-.357.935-.357 1.292 0l8.151 8.154 8.152-8.154c.357-.357.935-.357 1.293 0l1.276 1.28c.359.357.359.935 0 1.292l-8.15 8.154z"/></svg>');
					repeat: no-repeat;
					position: 50% 50%;
					size: 22px 22px;
				}
			}

			.menu-control-title {
				padding: 0 20px;
				line-height: 2.2;
				border-right: 70px transparent solid;
				white-space: nowrap;
				font-size: rem(20px);
				-ms-text-overflow: ellipsis;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		.menu-indicator {
			display: none;
		}
	}
}


/***********************
* DESKTOPOVÉ MENU
***********************/
@keyframes sipkaMenu {
	from {background-position: 10px 50%;}
	50% {background-position: 0 50%;}
	to {background-position: 10px 50%;}
}

@include bp("> #{$menu-tablet-size}") {
	.nadmenu {
		display: block;
	}

	#nadmenu {
		font-size: rem(14px);
		display: none;
		font-weight: normal;
		padding: 0;
		white-space: nowrap;

	}

	#menu .nadmenu a{
		display: inline-block;
		font-size: rem(30px);
		font-family: $font;
		font-weight: 600;
		color: $barZ;
		margin: 25px 0 5px 20px;
		padding: 12px 0 12px 40px;
		text-decoration: none;
		position: relative;
		z-index: 10;
		background: url(images/sipkaMenuZpet.png) no-repeat 10px 50%;
		animation-name: sipkaMenu;
		animation-duration: 1.5s;
		animation-iteration-count: 0;
		animation-timing-function: ease;

		&:hover{
			text-decoration: underline;
			animation-iteration-count: infinite;
		}
	}

	#menu {
		background-color: $menu-bg;
		padding: 0 0 40px 0;

		.menu-item-collapsed > ul {
			display: none;
		}

		.menu-item-expanded {
			> a .menu-indicator {
				transform: rotate(225deg);
			}

			> ul {
				display: block;
			}
		}

		.menu {
			font-size: rem(18px);
			font-family: $font;
			line-height: 1.2;
			padding: 0 6% 5px 8%;

			li:first-child{
				border-top: 1px solid #ffda82;
				padding-top: 16px;
			}

			ul {
				text-transform: none;
			}

			@include link {
				color: $menu-link-color;
				text-decoration: none;
			}

			@include link-over {
				color: $barZ;
			}

			@include link-all("#osmakt") {
				font-weight: bold;
				background-color: transparent;
				text-decoration: none;
				cursor: default;
				border: 0;
			}

			a {
				padding: 6px 0;
				display: block;
				position: relative;
				z-index: 10;
				margin-left: 0;
				text-decoration: none;
				font-size: rem(20px);
				font-family: $font;
				font-weight: 600;
			}

			.menu-indicator {
				width: 16px;
				height: 16px;
				overflow: hidden;
				position: absolute;
				top: .85em;
				left: -27px;
				z-index: 10;
				-webkit-transition: -webkit-transform .25s;
				transition: -ms-transform .25s;
				transition: transform .25s;

				img {
					margin: 0;
					cursor: pointer;
					font-size: rem(14px);
				}
			}

			ul {
				font-size: rem(16px);
				font-family: $sans-serif;
				margin: 0 0 0 -40px;
				padding: 2px 0 12px 0;

				a {
					padding: 1px 15px 0 40px;
					line-height: 1.5;
				}

				.menu-indicator {
					left: 13px;
					top: .23em;
				}

				ul {
					margin: 0;
					padding: 0 0 0 24px;
					position: static;
				}
			}
		}

		li {
			margin: 0;
			padding: 0;
			position: relative;
			display: block;

			li {
				border: 0;
				font-weight: normal;
			}

			// &.akt {
			// 	font-weight: bold;
			// 	text-decoration: none;
			// }
		}
	}
}
