/*!
  Author: WEBHOUSE, s. r. o.
  Version: 1.2
*/

/** PROMĚNNÉ PRO NASTAVENÍ ECCO **/

$using-autoprefixer: true !global; // Pokud true, mixiny nebudou vypisovat prefixy
$cache-hash: unique-id() !global; // Unikátní hash pro cestu k obrázkům

/*********************************/

@import "../../ASPINCLUDE/vismoWeb5/html/ecco2/ecco";

@import "bity";
@import "vars";
@import "typografie";
@import "barvy";

@import "komponenty/templaty";

@import "layout/styly";
@import "layout/zahlavi";

@import "komponenty/seznamy";
@import "komponenty/formulare";
@import "komponenty/tabulky";
@import "komponenty/galerie";
@import "komponenty/zalozky";
@import "komponenty/moduly";
@import "komponenty/kalendare";
@import "komponenty/menu";

//Odkomentovat, pokud bude potřeba:
//@import "komponenty/liteslider";
@import "komponenty/gte";
//@import "komponenty/diskuze";

@include dump-media;

// Pro debugování, před zveřejněním zakomentovat:
//@import "ecco-debug";
