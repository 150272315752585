
/***********************
*	ZÁLOŽKY
***********************/

.zalozky {
	font-size: rem(20px);
	line-height: 1.1;
	font-family: $font;
	font-weight: normal;
	border-bottom: 6px solid $barR;

	ul {
		@extend %sf;
		@extend %reset-list;
	}

	li {
		padding: 0;
		margin: 0 .5em 0 0;
		float: left;
		position: relative;
		display: block;

		&.azalozka {
			background:transparent none;
		}
	}

	a {
		display: block;
		text-decoration: none;
		margin: 0;
		position: relative;
		z-index: 20;
		cursor: pointer;
		padding: if(nth($form-padding, 2) == 0, 12px 20px 10px, 12px nth($form-padding, 2) 10px);
	}

	@include link {
		background-color: $tab-sub-background;
		color: $tab-sub-text;
	}

	@include link-over {
		text-decoration: underline;
	}

	.azalozka {
		@include link-all {
			background-color: $tab-sub-selected-background;
			color: $tab-sub-selected-text;
			text-decoration: none;
			cursor: default;
		}
	}

	@include bp("< 1000px"){
		a{
			padding: 12px 18px 10px;
		}	
	}

	@include bp("<= 600px"){
		font-size: rem(18px);
	}
}


#zahlavi2 {
	margin: 20px 0;
}


#zalozkynadpis {
	padding: 15px 0 0 0;
}

.vych_pol {
	font-size: rem(16px);
	text-align: right;
	padding: 0 0 10px 0;
	margin: 0 0 10px 0;
	text-transform: uppercase;
	
	strong {
		font-weight: normal;
	}
}


a[id*="k0"] {
	height: 0;
	overflow: hidden;
	line-height: 0;
	font-size: 0;
	color: transparent !important;
}

/****************************
*	S VYPNUTÝM JS NA TITULCE
****************************/

.bezjs {
	padding: 20px 0 15px 0;

	ul {
		@extend %reset-list;
	}

	.inline li::before {
		color: #404040;
	}
}

#keskryti {
	@extend %reset;
	display: inline;
}

/****************************
*	SE ZAPNTÝM JS NA TITULCE
****************************/

#titul .zalozky {
	font-size: rem(30px);
	line-height: 1.1;
	font-family: $font;
	position: relative;
	background-color: $barG;
	border-bottom: 8px solid $barR;
	padding-top: 28px;

	&::before, &::after{
		content: "";
		position: absolute;
		top: 0; bottom: -8px;
		width: 360px;
		background-color: $barG;
		border-bottom: 8px solid $barR;
	}

	&::before{
		right: 100%;
	}

	&::after{
		left: 100%;
	}	

	.inline li::before {
		content: normal;
	}

	li {
		@extend %reset;
		margin: 0 4px 6px;
		border: 2px solid white;
		border-bottom: 0;
		
		&:hover{
			border-color: $barZ;
		}
	}

	a {
		padding: 14px 32px;
		font-weight: 600;
		margin-bottom: -6px;
		position: relative;
		z-index: 3;
	}

	@include link {
		background-color: $tab-background;
		color: $tab-text;
	}

	@include link-over {
		color: $barZ;
		text-decoration: none;
	}

	.azalozka {
		border: 0;

		@include link-all {
			padding: 16px 32px 14px;
			background-color: $tab-selected-background;
			color: $tab-selected-text;
			text-decoration: none;
		}
	}

	@include bp("< 1000px"){
		padding-top: 22px;
		font-size: rem(24px);

		a{
			padding: 14px 24px;
		}
	}

	@include bp("< 800px"){
		padding-top: 16px;
		font-size: rem(20px);

		a{
			padding: 14px 16px;
		}
	}	
}

@include bp("< 650px") {
	#linkynakotvy {
		display: none;
	}

	#titul .poz {
		h2.cist {
			position: static;
			width: auto;
			height: auto;
		}

		.skryty {
			display: block;
		}
	}
}
