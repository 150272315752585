
/******************
* MODULY
******************/

#uvodtitul {
	overflow: hidden;
	margin: 0 0 30px 0;
}

#upozorneni {
	text-align: left;
	background-color: $barG;
	position: absolute;
	right: 0;
	bottom: 8px;
	width: 100%;
	max-width: 800px;
	color: white;
	font-size: rem(17px);
	font-family: $font2;
	display: table;
	
	p, h2.cvi{
		box-sizing: border-box;
		display: table-cell;
		vertical-align: middle;
		height: 59px;
		padding: 6px 32px 5px;
	}

	p{	
		background: url(images/sipkaUpozorneni.png) no-repeat left center;
	}

	h2.cvi{
		font-size: rem(25px);
		font-weight: 600;
		background-color: $barR;
		color: white;
		white-space: nowrap;	
	}

	a{
		color: white;

		&:hover{
			text-decoration: none;
		}
	}

	@include bp("< 800px"){
		position: static;
	}

	@include bp("<= 600px"){
		
		h2.cvi, p{
			display: block;
			padding: 16px 24px 15px;
			height: auto;
			min-height: 59px;
		}

		p{
			background: transparent;
			min-height: auto;
		}

		h2.cvi{
			margin: 0;
		}
	}
}

#kalendarAkci {
	.editor {
		margin-top: 40px;
		margin-bottom: 40px;
	}
}


#mbannery1, #mbannery2 {
	font-size: rem(14px);
	padding: 40px 0;
	margin: 0;
	color: #000000;
	background: #ffffff;

	.editor {
		text-align: center;

		@include link-all {
			color: #000000 !important;
		}
	}
	
	li { 
		margin-top: 0;
		margin-bottom: 0;
	}
}

#mbannery1{
	padding: 55px 0;
}

#mbannery2{
	li:nth-child(2) img{
		max-height: 125px;
		width: auto!important;
		padding: 0 16px;
	}
}

#jmeniny {
	padding: 6px 12px 2px 15px;
	text-align: left;
	
	* {
		display: inline;
	}
}

#obalKontakt {
	#kontakt{
		max-width: 16em;
		margin: 0 auto;
		font-size: rem(17px);
		line-height: 1.4;
	}

	address {
		display: block;
		font-style: normal;
		margin: 0 0 15px 0;

		.telefon{
			display: inline-block;
			margin-top: 32px;
		}
	}
	
	strong {
		font-weight: bold;
	}

	.dalsi {
		a{
			display: block;
			color: #2a2a2a;
			font-family: $font;
			font-size: rem(20px);
			position: relative;
			background-color: $barZ;
			border-radius: 3px;
			text-decoration: none;
			width: 11.25em;
			float: left;
			margin: 20px 0 32px;
			text-transform: uppercase;
		
			span{
				position: relative;
				padding: 8px 10px;
				z-index: 2;
				display: block;
				font-weight: 600;
				text-align: center;
			}

			&::before{
				position: absolute;
				z-index: 1;
				content: "";
				top: 0; right: 0; left: 0;
				background-color: #F4C744;
				transition: height 0.5s;
				height: 0;
				border-radius: 3px;
			}

			&:hover{
				text-decoration: underline;

				&::before{
					height: 100%;
				}
			}	
		}
	}	
}

#dotazy{
	display: block;
	text-align: center;
	padding: 10px 16px;
	box-sizing: border-box;
	border: 1px solid $barZ;
	color: $barZ!important;
	text-transform: uppercase;
	margin: 30px 0;
	text-decoration: none;

	&:hover{
		color: white!important;
		border-color: white;
	}
}

#anketa {
	p {
		padding: 0;
		margin: 0 0 1em 0;
	}

	ul {
		list-style-type: none;
		padding: 0;
		margin: 8px 0 5px 0;

		li {
			margin-bottom: 8px;
		}
	}

	.hlas {
		margin-top: 3px;
		z-index: 1;
		
		div {
			height: 12px;
			background-color: $color-main;
		}
	}
}

#pocitadlo {
	margin: 15px 0 0 0;
	text-align: center;
	padding-bottom: 15px;

	ul.inline {
		padding-left: 0;
		display: inline;

		li span {
			margin: 0 0 0 2px;
		}
	}
}

#obalKalendar{
	background-color: #f7f7f1;
	
	.centrovany{
		max-width: 1212px;
	}

	.obaldalsi{
		margin-top: 0;
		padding: 26px 0;
		border-top: 2px solid #f4c744;

		.dalsi{
			border: 0;
			
			&::after{
				background-color: #F4C744;
			}	
		}
	}
}

#kalendarNadpis{
	background-color: $barG;
	border-bottom: 8px solid $barZ;
	padding-top: 19px;

	h2.cvi{
		color: #2c2c2c;
		background-color: $barZ;
		float: left;
		font-weight: 600;
		font-size: rem(30px);
		padding: 20px 32px 13px;
		margin: 0;
	}

	#kalendarDatum{
		float: right;
		color: white;
		font-family: $font2;
		font-size: rem(17px);
		padding: 34px 0 11px 16px;

		p{
			margin: 0;
		}
	}
}