
/*******************************
* KALENDÁŘE
*******************************/

$bunka: (100% / 7);

/* #kalakci {
	p {
		clear: both;
	} */

	#kalakci {
		margin-bottom: 30px;
	}

	.mesice {
		@extend %reset;
		display: table;
		width: 100%;
		line-height: .8;
		table-layout: fixed;
		border-spacing: 1px 0;
		margin-bottom: 10px;

		+ p {
			margin: 20px 0;
		}

		a {
			display: table-cell;
			padding: 1em 0;
			text-align: center;
			vertical-align: middle;
			text-decoration: none;

			&.sipka {
				width: $bunka;
				font-family: $font;
				position: relative;
			}
		}

		@include link {
			color: #ffffff;
			background-color: $barG;
		}
	}

	.calcells {
		th {
			font-weight: 700;
			color: $cal-title-notmonth-text;
			background-color: $cal-title-notmonth-background;
			border-bottom: 10px transparent solid;
			background-clip: padding-box;
		}

		th,
		td {
			padding: $cal-cell-padding 0;
		}

		td {
			> a {
				padding: $cal-cell-padding 0;
				margin: -$cal-cell-padding 0;
			}

			&,
			> #{selector-link()} {
				background-color: $cal-title-month-background;
				color: $cal-title-month-text;
			}

			&.notmnth {
				background-color: $cal-title-notmonth-background;
				color: $cal-title-notmonth-text;

				> #{selector-link()} {
					background-color: $cal-title-notmonth-background;
					color: $cal-title-notmonth-text;
				}
			}

			&.curdate {
				border: 2px $cal-title-current-border solid;
				padding: 0;

				> a {
					padding: ( $cal-cell-padding - 2px ) 0;
					margin: -2px 0;
				}
			}

			&.wkday,
			&.notmnth {
				> #{selector-link-over()} {
					background-color: $cal-hover-background;
					color: $cal-hover-text;
				}	
			}
		}
	}

	#stranka .calcells {
		th {
			color: $cal-title-notmonth-text;
			background-color: $cal-title-notmonth-background;
			font-weight: 700;
		}

		td {
			&.wkday,
			&.wkend {
				&,
				> #{selector-link()} {
					background-color: $cal-sub-month-background;
					color: $cal-sub-month-text;
				}
			}

			//&.notmnth {
			> #{selector-link()} {
				background-color: $cal-sub-notmonth-background;
				color: $cal-sub-notmonth-text;
			}
			//}

			&.curdate {
				border: 2px $cal-sub-current-border solid;
				border-color: $cal-sub-current-border;
			}

			&.wkday,
			&.wkend,
			&.notmnth {
				> #{selector-link-over()} {
					background-color: $cal-hover-background;
					color: $cal-hover-text;
				}
			}

			&.cell_selected {
				&,
				> #{selector-link-all} {
					background-color: $cal-sub-selected-background;
					color: $cal-sub-selected-text;
				}
			}
		}
	}

		// Nasledujici odkomentovat, pokud maji bubliny vylezat zleva
		//.floatingBubbleElm {
		//	margin-left: -15px !important;
		//}
//}
