@charset "UTF-8";
/*!
  Author: WEBHOUSE, s. r. o.
  Version: 1.2
*/
/** PROMĚNNÉ PRO NASTAVENÍ ECCO **/
/*********************************/
/*! ECCO SASS knihovna
    @version: 1.0.4 */
/*****************************************
* ECCO - GLOBAL VARIABLES
*****************************************/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*****************************************
* ECCO - PLACEHOLDERS
*****************************************/
.mesice, #titul .zalozky li, #keskryti, #hledani .btn, .obrodkazy li, body, #menu ul, #menu1 ul, .bezjs ul, .zalozky ul, .titulodkazy .ui, .obrodkazy .ui, .dok ul, #lista ul {
  margin: 0;
  padding: 0;
}

#menu ul, #menu1 ul, .bezjs ul, .zalozky ul, .titulodkazy .ui, .obrodkazy .ui, .dok ul, #lista ul {
  list-style-type: none;
}

#hledani label.label-skryty::after, .titulformular label.label-skryty::after, #nazev a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.odkazy .ui li li li::before, .aktuality2 li.bezobr li li::before, .utvary .ui li.o li li::before, .kategorie .ui li li li::before, .utvary .ui li li li::before, .galerie .ui li li li::before, #stred,
#vpravo,
#vlevo {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.zalozky ul::after, #pozicovani .cards-horizontal ul::after, #pozicovani .cards ul::after, #lista::after, #hlava::after, #telo::after,
#stred::after, .centrovany::after {
  content: "";
  display: table;
  font-size: 0;
  clear: both;
}

/*****************************************
* ECCO - FLEXBOX
*****************************************/
/***********************************
/* HODNOTY BITŮ PRO NASTAVENÍ WEBU 
***********************************/
/*************************
* GLOBÁLNÍ PROMĚNNÉ
*************************/
/************************/
/*************************
* PROMĚNNÉ KOMPONENT
*************************/
/** TYPOGRAFIE **/
/** FORMULÁŘE **/
/** TABULKY **/
/** SEZNAMY **/
/** AKCE **/
/** KALENDÁŘE **/
/** MENU **/
/** ZÁLOŽKY **/
/************************/
/***********************************************************
* PROMĚNNÉ BREAKPOINTŮ PRO SNADNĚJŠÍ ZADÁVÁNÍ MIXINU "BP"
***********************************************************/
/**********************************************************/
/*******************
* NADPISY
*******************/
h1.cvi {
  font-size: 1.25rem;
  padding: 0;
  line-height: 1.1;
  margin: 0 20px;
  font-family: RajdhaniWeb;
  font-weight: 600;
  color: #666666;
}
h1.cvi strong {
  font-size: 2.6rem;
  font-weight: 600;
  display: block;
  color: #484848;
  letter-spacing: -0.025em;
  text-transform: uppercase;
}
@media screen and (max-width: 1219px) {
  h1.cvi strong {
    font-size: 2.125rem;
  }
}
@media screen and (max-width: 649px) {
  h1.cvi strong {
    font-size: 1.875rem;
  }
}
@media screen and (max-width: 500px) {
  h1.cvi strong {
    font-size: 2.125rem;
  }
}
@media screen and (min-width: 500px) and (max-width: 579px) {
  h1.cvi {
    margin: 0;
  }
}
h1.cvi span {
  display: block;
  margin: -2px 0 -3px 3px;
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
#prohlaseni h3,
h4 {
  font-family: RajdhaniWeb;
}

.poz h2.cist {
  font-size: 2.5rem;
  color: #c81324;
  font-weight: 600;
  margin: 32px 0 0;
  padding: 0;
  line-height: 1.2;
  text-align: center;
}

#zahlavi h2.cvi {
  margin: 20px 0 30px 0;
  padding: 0;
}

#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
  font-size: 2.1875rem;
  color: #444444;
  font-weight: 700;
}

.editor h3, h3.cvi, h3.ud, #prohlaseni h3 {
  font-size: 1.75rem;
  font-weight: 700;
  margin: 25px 0 5px 0;
  padding: 8px 20px 4px 0;
  line-height: 1.2;
}

h4 {
  font-size: 1.375rem;
  line-height: 1.2;
}
h4.ud {
  font-weight: 600;
}

h4.urd-list {
  font-size: 1.5625rem;
  padding-top: 0.75em;
  float: none;
  border-top: 3px #cccccc solid;
  padding-right: 7em;
}
h4.urd-list + .udz.urd-list {
  margin-top: -1.6em;
  margin-bottom: 2em;
  float: none;
  text-align: right;
}
@media screen and (max-width: 1023px) {
  h4.urd-list {
    padding-right: 0;
  }
  h4.urd-list + .udz.urd-list {
    margin-top: 1em;
  }
}

h5 {
  font-size: 1.1em;
}

@font-face {
  font-family: "RajdhaniWeb";
  font-weight: normal;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/rajdhani-regular-webfont.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/rajdhani-regular-webfont.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/rajdhani-regular-webfont.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "RajdhaniWeb";
  font-weight: 500;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/rajdhani-medium-webfont.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/rajdhani-medium-webfont.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/rajdhani-medium-webfont.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "RajdhaniWeb";
  font-weight: 600;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/rajdhani-semibold-webfont.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/rajdhani-semibold-webfont.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/rajdhani-semibold-webfont.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "RajdhaniWeb";
  font-weight: bold;
  font-style: normal;
  src: url("/ASPINCLUDE/vismoWeb5/html/fonty/rajdhani-bold-webfont.woff2") format("woff2"), url("/ASPINCLUDE/vismoWeb5/html/fonty/rajdhani-bold-webfont.woff") format("woff"), url("/ASPINCLUDE/vismoWeb5/html/fonty/rajdhani-bold-webfont.ttf") format("truetype");
  font-display: swap;
}
html {
  line-height: 1.375;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 18px;
}
@media screen and (min-width: 601px) {
  html {
    font-size: 16px;
  }
}

body.editor {
  background-color: #ffffff;
}

.editor h3 {
  font-weight: normal;
  margin: 10px 0;
  padding: 0;
  font-family: RajdhaniWeb;
  line-height: 1.2;
}

body,
.editor {
  font-size: 1em;
}

body,
.automat {
  color: #444444;
}

a,
a:link,
a:visited {
  color: #4f4f4f;
  text-decoration: underline;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

hr,
hr.oddelovac {
  color: #dddddd;
  background-color: #dddddd;
}

body,
input,
button,
textarea,
select {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
}

.zvyrazneni,
.vystraha,
.strlistovani strong {
  color: #BD013E;
  background-color: #ffffff;
  padding: 0 3px;
}

.vystraha {
  border-radius: 3px;
}

.zvyrazneni2 {
  background-color: #303030;
  color: #ffffff;
  padding: 0 2px;
}

strong.zprava {
  font-size: 1.1em;
}

/*************************
* ŠABLONY
*************************/
/*************************
* DOKUMENTY
*************************/
#pozicovani .cards li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  margin-top: 0;
  margin-bottom: 20px;
  background-color: #ffffff;
  overflow: hidden;
}
@media screen and (max-width: 499px) {
  #pozicovani .cards li {
    width: 100%;
  }
}
@media screen and (min-width: 500px) and (max-width: 1023px) {
  #pozicovani .cards li {
    width: 49%;
    margin-left: 2%;
  }
  #pozicovani .cards li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  #pozicovani .cards li {
    width: 32%;
    margin-left: 2%;
  }
  #pozicovani .cards li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
#pozicovani .cards li img {
  float: none;
  display: table;
  margin: 0;
}
#pozicovani .cards li .dok-upoutavka {
  display: block;
  overflow: hidden;
  margin: 0 -20px;
}
#pozicovani .cards li .dok-nazev {
  padding-top: 10px;
  display: block;
}
#pozicovani .cards li div {
  margin-top: 8px;
}

#pozicovani .cards-horizontal li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 15px 20px;
  margin-top: 0;
}
@media screen and (max-width: 799px) {
  #pozicovani .cards-horizontal li {
    width: 100%;
  }
}
@media screen and (min-width: 800px) {
  #pozicovani .cards-horizontal li {
    width: 49%;
    margin-left: 2%;
  }
  #pozicovani .cards-horizontal li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
#pozicovani .cards-horizontal li.u {
  padding-left: 150px;
}
#pozicovani .cards-horizontal li img {
  float: left;
  margin: -15px 0 0 -150px;
  max-width: 130px;
}
@media screen and (max-width: 419px) {
  #pozicovani .cards-horizontal li img {
    float: none;
    display: table;
    margin: 0 0 10px 0;
  }
}

/**************************
* DEFINICE PROMĚNNÝCH
**************************/
/*************************/
/**************************
* OBECNÉ
**************************/
html {
  touch-action: manipulation;
}

html,
body {
  background-color: #ffffff;
}

body {
  text-align: center;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.expandable):not(.menu-link):not(.mouse-focus):focus {
  border-radius: 3px;
  box-shadow: 0 0 0 2000px #303030 inset, 0 0 0 3px #303030 !important;
  color: #ffffff !important;
  outline: 0;
}

/*************************
* HLAVNÍ OBSAH
*************************/
.centrovany {
  width: 94%;
  max-width: 1280px;
  margin: 0 auto;
  text-align: left;
}

.centCelek {
  max-width: 1212px;
}

#hlava {
  width: 100%;
}

#hlobsah,
#navigace,
#navigace2 {
  display: block;
}

#obalcelek {
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  background-color: #eff0eb;
}
#stranka #obalcelek {
  background-color: #ffffff;
}
#stranka #obalcelek #celek {
  width: 100%;
}

#stred,
#vpravo,
#vlevo {
  width: 100%;
  float: none;
  margin-bottom: 30px;
}

#vpravo,
#vlevo {
  word-wrap: break-word;
}

#stranka .editor,
.perex {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  color: #4f4f4f;
  font-size: 1.0625rem;
}

#stranka #stred {
  padding: 15px 5% 15px 3.9%;
}
@media screen and (max-width: 799px) {
  #stranka #stred {
    padding: 15px 4%;
  }
}

/*************************************
* TABULKOVÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/
/*************************************
* FLOATOVANÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/
@media screen and (min-width: 801px) {
  #stred {
    float: left;
    width: 67.1875%;
  }
  #stranka #stred {
    float: right;
    background-color: #ffffff;
  }
  #vpravo,
  #vlevo {
    width: 32.1875%;
  }
  #vpravo {
    float: right;
  }
  #vlevo {
    float: left;
  }
}
/************************
* PRVKY STRÁNKY
************************/
#obalCesta {
  background-color: #f3f3ef;
}
#obalCesta .centrovany {
  max-width: 1234px;
  margin: 0 auto;
  width: 92%;
}

.cesta {
  font-size: 0.9375rem;
  padding: 19px 0 18px;
  margin: 0;
  position: relative;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  color: #444444;
}
.cesta strong {
  font-weight: normal;
}

.box {
  padding: 0 15px 10px 15px;
  background: #cccccc;
  margin: 0 0 15px 0;
}

.obaldalsi {
  text-align: center;
  margin: 16px 0 32px;
}
.obaldalsi .dalsi {
  display: inline-block;
  position: relative;
  height: 2.45em;
  font-size: 1.25rem;
  background-color: #ffda82;
  text-align: center;
  border-radius: 3px;
  box-sizing: border-box;
  border: 2px solid #ffda82;
  width: 8em;
}
.obaldalsi .dalsi::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  transition: height 0.5s;
  height: 0;
  background-color: white;
  z-index: 2;
  border-radius: 3px;
}
.obaldalsi .dalsi:hover::after {
  height: 100%;
}
.obaldalsi .dalsi a {
  font-size: 1.25rem;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  color: #2a2a2a;
  font-family: RajdhaniWeb;
  font-weight: 600;
  padding: 10px 16px;
  box-sizing: border-box;
}
.obaldalsi .dalsi a,
.obaldalsi .dalsi a:link,
.obaldalsi .dalsi a:visited {
  text-decoration: none;
}
.obaldalsi .dalsi a:hover,
.obaldalsi .dalsi a:focus,
.obaldalsi .dalsi a:active {
  text-decoration: underline;
}

/************************
* ZÁPATÍ
************************/
#nadpatou {
  background: #454c5b;
  border-top: 8px solid #c81324;
  padding: 50px 0 40px;
}
#nadpatou h2.cvi {
  color: #ffda82;
  font-size: 2.1875rem;
  font-family: RajdhaniWeb;
  text-align: center;
  padding: 0 0 32px;
  margin: 0;
  font-weight: 600;
}
@media screen and (max-width: 600px) {
  #nadpatou {
    padding: 30px 0 40px;
  }
  #nadpatou h2.cvi {
    padding: 0 0 10px;
  }
}
#nadpatou,
#nadpatou h3.cvi,
#nadpatou a, #nadpatou a:link, #nadpatou a:visited {
  color: #ffffff;
}
#nadpatou .boxNp {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  padding: 0 4%;
  box-sizing: border-box;
}
@media screen and (max-width: 600px) {
  #nadpatou .boxNp {
    width: 100%;
  }
}
@media screen and (min-width: 601px) and (max-width: 899px) {
  #nadpatou .boxNp {
    width: 50%;
    margin-left: 0%;
  }
  #nadpatou .boxNp:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 900px) {
  #nadpatou .boxNp {
    width: 33.3333333333%;
    margin-left: 0%;
  }
  #nadpatou .boxNp:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 601px) {
  #nadpatou #napiste {
    border-left: 1px solid #717680;
  }
}
@media screen and (min-width: 900px) {
  #nadpatou #napiste {
    border-right: 1px solid #717680;
  }
}
@media screen and (min-width: 601px) and (max-width: 899px) {
  #nadpatou #abo {
    margin-top: -200px;
  }
}

#obalpata {
  background-color: #eff0eb;
  min-width: 320px;
}

#pata {
  text-align: center;
  padding: 22px 0 28px;
  color: #444444;
  box-sizing: border-box;
  font-size: 0.875rem;
}
#pata * {
  line-height: 2.2;
  border: 0;
}
#pata li + li::before {
  content: "|";
  margin: 0 8px 0 0;
}
#pata .partWebmaster1::before {
  content: "|";
  margin: 0 10px 0 0;
}
#pata a,
#pata a:link,
#pata a:visited {
  color: #444444;
  text-decoration: underline;
}
#pata a:hover,
#pata a:focus,
#pata a:active {
  text-decoration: none;
}
#pata .patalogo {
  padding: 1px 6px;
  margin-right: 11px;
}
#pata #pocitadlo {
  margin: 0 !important;
  padding-bottom: 0 !important;
}
#pata .webmaster,
#pata .inline {
  display: inline-block;
  margin: 0;
  padding: 0 0 0 3px;
}
#pata .webmaster li,
#pata .inline li {
  padding: 0 5px;
}
#pata .webmaster li a,
#pata .inline li a {
  margin: 0 0 0 1px;
}
#pata .webmaster {
  display: block;
}
#stranka #pata .webmaster {
  margin-top: 2px;
}
#pata .webmaster .partWebmaster {
  display: inline-block;
  margin-left: 5px;
}
#pata ul.inline li {
  display: inline-block;
}

#obalmbannery {
  min-width: 320px;
}

@media screen and (max-width: 599px) {
  #pocitadlo li {
    display: block !important;
  }
  #pocitadlo li + li::before {
    content: "";
    margin: 0;
  }
}
@media screen and (max-width: 499px) {
  .partWebmaster1 {
    display: block !important;
    margin-top: 2px;
  }
  .partWebmaster1::before {
    content: "" !important;
    margin: 0 !important;
  }
}
#unpobtekane {
  margin-left: 0 !important;
}

.popis.dpopis .zodpovida,
.popis.dpopis .zodpovida + br {
  display: none;
}

.noleftpanel .vol-sdileni {
  margin-top: -10px;
  margin-bottom: 40px;
}

h3.cvi.souvisejiciodkazy {
  display: none;
}

/*************************
* ZÁHLAVÍ
*************************/
/*************************
* HLAVA
*************************/
#obalhlava {
  position: relative;
  z-index: 13;
  background-color: #f3f3ef;
  color: #2a2a2a;
}
#obalhlava a,
#obalhlava a:link,
#obalhlava a:visited {
  color: #2a2a2a;
}

#hlava {
  clear: both;
  position: relative;
  z-index: 3;
}

#znak {
  margin: 0;
  position: relative;
  z-index: 3;
}
@media screen and (max-width: 649px) {
  #znak {
    width: 70px;
  }
}
@media screen and (max-width: 579px) {
  #znak {
    display: none;
  }
}

#nazev {
  text-align: center;
  position: relative;
  margin: 8px 0 -14px 2.34375%;
}
#nazev a {
  z-index: 30;
  display: inline;
  text-decoration: none !important;
  outline-width: 0;
  font-size: 0;
}
#nazev a img {
  font-size: 1rem;
  line-height: 1;
}
#nazev a::after {
  content: "";
}

#jazykyGte {
  float: right;
  margin: 28px 2.8125% 0 0;
}
@media screen and (max-width: 999px) {
  #jazykyGte {
    margin: 16px 2.2% 12px 50px;
  }
}

#logoBrno {
  float: right;
  margin: 26px 2.03125% 0 2.65625%;
}
@media screen and (min-width: 601px) and (max-width: 849px) {
  #logoBrno {
    position: absolute;
    top: 58px;
    right: 160px;
  }
}

@media screen and (min-width: 501px) {
  #znak,
  #nazev,
  #nazev a,
  h1.cvi {
    float: left;
  }
  #nazev {
    text-align: left;
  }
}
/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/
#obalobrazek {
  position: relative;
  display: block;
  clear: both;
  max-width: 1920px;
  margin: 0 auto;
}

#obrazek {
  display: none;
}

@media screen and (min-width: 800px) {
  #obalobrazek {
    padding-bottom: 23.9583333333%;
  }
}
@media screen and (min-width: 1921px) {
  #obalobrazek {
    padding-bottom: 460px;
  }
}

@media screen and (min-width: 600px) {
  #obrazek_sp {
    background: url(images/bg_top_sp.jpg) no-repeat center top;
    padding-bottom: 300px;
    background-size: contain;
  }
}
@media screen and (min-width: 600px) and (max-width: 1919px) {
  #obrazek_sp {
    padding-bottom: 15.625%;
  }
}
@media screen and (min-width: 601px) {
  #obrazek {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}
@media screen and (min-width: 601px) and (max-width: 799px) {
  #obrazek {
    position: static !important;
    padding-bottom: 23.9583333333%;
  }
}
#obaljazyky {
  display: inline-block;
  margin: 0;
}
#obaljazyky ul {
  margin: 0;
  padding: 0;
}
#obaljazyky span {
  font-weight: normal;
}
#obaljazyky a {
  text-decoration: underline;
  font-family: RajdhaniWeb;
}
#obaljazyky a:hover {
  text-decoration: none;
}
#obaljazyky li {
  margin: 0 7px;
  display: inline-block;
}

#jazyky .goog-te-menu2-item div,
#jazyky .goog-te-menu2-item:link div,
#jazyky .goog-te-menu2-item:visited div,
#jazyky .goog-te-menu2-item:active div {
  background-color: transparent;
}
#jazyky .goog-te-menu2-item:hover div {
  background-color: transparent;
}
#jazyky .goog-te-menu2-item-selected div,
#jazyky .goog-te-menu2-item-selected:link div,
#jazyky .goog-te-menu2-item-selected:visited div,
#jazyky .goog-te-menu2-item-selected:hover div,
#jazyky .goog-te-menu2-item-selected:active div {
  font-weight: normal;
}
#jazyky a > div {
  padding: 0;
}
#jazyky a > div .indicator {
  display: none;
}
#jazyky .text {
  font-size: 0;
}
#jazyky .text::before {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2a2a2a;
}
#jazyky .text.cs::before {
  content: "CS";
}
#jazyky .text.en::before {
  content: "EN";
}
#jazyky .text.de::before {
  content: "DE";
}
#jazyky div {
  display: inline;
}

#lista {
  text-align: right;
  padding: 6px 0 2px 12px;
  position: relative;
}
#lista ul {
  position: relative;
}
#lista ul.inline {
  display: inline;
}
#lista li {
  margin: 0;
  white-space: nowrap;
}
#lista li.skip {
  position: absolute;
  display: block;
  top: 0 !important;
  right: 0 !important;
  max-width: 100%;
  padding: 1px;
  border: 0;
}
#lista li.skip a {
  text-align: center;
  border: 0;
  position: absolute;
  right: 0;
  top: -500px;
  z-index: 0;
  padding: 0 2px;
  background-color: #ffffff;
  color: #000000;
}
#lista li.skip a:focus,
#lista li.skip a:active {
  z-index: 10 !important;
  position: absolute !important;
  right: 10px;
  top: 5px;
}

/*************************
* SEZNAMY
*************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/************************/
.odkazy .ui li li li::before, .aktuality2 li.bezobr li li::before, .utvary .ui li.o li li::before, .kategorie .ui li li li::before, .utvary .ui li li li::before, .galerie .ui li li li::before {
  position: relative;
  top: -0.08em;
  margin-right: 7px;
  display: inline-block;
  content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='7px' height='7px' viewBox='0 0 7 7'%3E%3Cpath fill='%23BCBCBC' stroke='%23BCBCBC' stroke-miterlimit='10' d='M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z'/%3E%3C/svg%3E");
  vertical-align: middle;
  line-height: 0;
  overflow: hidden;
}
li.u, li.typsouboru {
  list-style-type: none !important;
}
li.u::before, li.typsouboru::before {
  content: normal !important;
}
.odkazy ol li::before::before {
  content: normal !important;
}

dl dt {
  font-size: 1.1875rem;
  color: #c81324;
  line-height: 1.1;
}

dl.kontakty .utvary {
  padding-top: 4px;
}

.ui li > strong:first-child {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 1.3125rem;
}
.ui li > strong:first-child a,
.ui li > strong:first-child a:link,
.ui li > strong:first-child a:visited {
  color: #c81324;
}
.utvary .ui li > strong:first-child, .bodkazy .ui li > strong:first-child, .souvisejiciodkazy .ui li > strong:first-child {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 1rem;
  font-weight: bold;
}
.utvary .ui li > strong:first-child a,
.utvary .ui li > strong:first-child a:link,
.utvary .ui li > strong:first-child a:visited, .bodkazy .ui li > strong:first-child a,
.bodkazy .ui li > strong:first-child a:link,
.bodkazy .ui li > strong:first-child a:visited, .souvisejiciodkazy .ui li > strong:first-child a,
.souvisejiciodkazy .ui li > strong:first-child a:link,
.souvisejiciodkazy .ui li > strong:first-child a:visited {
  color: #c81324;
}
.ui li > strong:first-child img {
  font-size: 0.875rem;
}
.utvary .ui li li > strong:first-child, .bodkazy .ui li li > strong:first-child, .souvisejiciodkazy .ui li li > strong:first-child {
  font-weight: normal;
}
.ui ul li > strong:first-child {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 1rem;
}
.ui ul li > strong:first-child a,
.ui ul li > strong:first-child a:link,
.ui ul li > strong:first-child a:visited {
  color: #4f4f4f;
}
.ui li {
  clear: none;
  padding: 0;
  margin: 0 0 5px 0;
}
.ui li strong {
  font-weight: normal;
  line-height: 1.2;
}
dl.kontakty .utvary .ui li strong + div, #stromutvaru .utvary .ui li strong + div, .odkazy .ui li strong + div {
  padding: 0;
  color: #666666;
  line-height: 1.4;
  font-style: italic;
}
dl.kontakty .utvary .ui li strong + div {
  margin-bottom: 10px;
}
.ui li .ui {
  padding-top: 0;
  padding-bottom: 10px;
}
.ui li .ui .ui {
  padding-bottom: 0;
}
.ui li .ui .ui .ui {
  padding-left: 18px;
}
.ui li li {
  margin: 5px 0;
}
.ui li li::after {
  content: normal;
}
.ui ol li {
  padding-left: 0;
}
.ui div {
  font-weight: normal;
  margin-bottom: 2px;
  margin-top: 6px;
  padding-top: 0;
  padding-bottom: 0;
}

dl.kontakty .utvary ul.ui {
  margin-left: 0;
  margin-bottom: 15px;
}
dl.kontakty .utvary li strong {
  font-size: 0.9375rem;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
}
dl.kontakty .utvary li strong a,
dl.kontakty .utvary li strong a:link,
dl.kontakty .utvary li strong a:visited {
  color: #4f4f4f;
}

/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/
#isvs .hodnota li::before {
  content: normal !important;
}

/*********************
* VÝPISY DOKUMENTŮ
*********************/
.dok li {
  display: block;
  margin: 20px 0;
  padding: 0;
}
.dok li:first-child {
  margin-top: 8px;
}
.dok .n5-akce-typ,
.dok .dok-doplnek,
.dok .ktg,
.dok strong + span,
.dok .vd-priloha {
  font-size: 0.9375rem;
  color: #444444;
}
.dok .n5-akce-typ a,
.dok .n5-akce-typ a:link,
.dok .n5-akce-typ a:visited,
.dok .dok-doplnek a,
.dok .dok-doplnek a:link,
.dok .dok-doplnek a:visited,
.dok .ktg a,
.dok .ktg a:link,
.dok .ktg a:visited,
.dok strong + span a,
.dok strong + span a:link,
.dok strong + span a:visited,
.dok .vd-priloha a,
.dok .vd-priloha a:link,
.dok .vd-priloha a:visited {
  color: #444444;
}
.dok .vd-priloha {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
}
.dok strong {
  font-weight: normal;
}
.dok strong img {
  margin: 3px 20px 6px 0;
  float: left;
}
.dok strong a {
  font-size: 1.375rem;
  font-family: RajdhaniWeb;
  color: #c81324;
  line-height: 1.1;
}

#zpravodajstvi ul.inline {
  padding: 0;
  margin: 20px 0;
}

#kalendarAkci .dok li.u {
  padding-left: 150px;
}
#kalendarAkci .dok li.u strong img {
  margin-left: -150px;
}

/** DOKUMENTY NA TITULCE **/
#pozicovani {
  padding-bottom: 20px;
}
@media screen and (min-width: 650px) {
  #pozicovani .poz {
    margin-bottom: 25px;
  }
}
#pozicovani .cards ul {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -moz-box-wrap: nowrap;
  -webkit-box-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -ms-flexbox-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -moz-box-wrap: wrap;
  -webkit-box-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flexbox-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 48px;
}
#pozicovani .cards li {
  padding: 28px 32px 75px;
  position: relative;
}
#pozicovani .cards li::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 9px;
  transition: background 1s, height 0.5s;
  height: 0;
  background: #2b3a70;
}
#pozicovani .cards li:hover::after {
  height: 100%;
  background: #c81324;
}
#pozicovani .cards .dok-datum {
  font-family: RajdhaniWeb;
  font-size: 1.25rem;
  font-weight: 600;
  color: #676767;
}
#pozicovani .cards strong a {
  color: #2b3a70;
}
#pozicovani .cards strong a:hover {
  color: #c81324;
}
#pozicovani .cards .dok-nazev {
  font-family: RajdhaniWeb;
  font-size: 1.5625rem;
  font-weight: 600;
}
@media screen and (max-width: 799px) {
  #pozicovani .cards .dok-nazev {
    font-size: 1.375rem;
  }
}
#pozicovani .cards .popis {
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 1.0625rem;
  color: #454545;
}
#pozicovani .cards .dok-slozka {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 18px;
  padding: 0 32px;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 0.9375rem;
  color: #393939;
}
@media screen and (max-width: 799px) {
  #pozicovani .cards li {
    padding: 24px 20px 80px;
  }
  #pozicovani .cards .dok-slozka {
    padding: 0 20px;
  }
}
@media screen and (max-width: 649px) {
  #pozicovani .cards ul {
    margin-top: 24px;
  }
}
@media screen and (max-width: 599px) {
  #pozicovani .cards li:nth-of-type(4) ~ li {
    display: none;
  }
}
#pozicovani .cards-horizontal ul {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -moz-box-wrap: nowrap;
  -webkit-box-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -ms-flexbox-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -moz-box-wrap: wrap;
  -webkit-box-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flexbox-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (min-width: 650px) {
  #pozicovani .skryty {
    display: none;
  }
}
#pozicovani .obaldalsi {
  text-align: center;
}
@media screen and (max-width: 649px) {
  #pozicovani .obaldalsi {
    margin-bottom: 0;
  }
}
#pozicovani .obaldalsi .dalsi {
  display: inline-block;
  position: relative;
  width: 12.3em;
  height: 2.85em;
  font-size: 1.25rem;
  background-color: #c81324;
  text-align: center;
  padding: 0;
  border-radius: 3px;
  border: 0;
}
#pozicovani .obaldalsi .dalsi::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  transition: height 0.5s;
  height: 0;
  background-color: #b90414;
  z-index: 2;
  border-radius: 3px;
}
#pozicovani .obaldalsi .dalsi:hover::after {
  height: 100%;
}
#pozicovani .obaldalsi .dalsi a {
  font-size: 1.25rem;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  color: white;
  font-family: RajdhaniWeb;
  font-weight: 600;
  padding: 15px;
  box-sizing: border-box;
}

/*******************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ	
*******************************/
.obrodkazy li {
  display: block;
  margin-bottom: 13px;
  background-image: none;
  position: relative;
}
.obrodkazy li::before {
  content: normal !important;
}
.obrodkazy a {
  font-size: 1.25rem;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  background-repeat: no-repeat;
  background-position: 0 50%;
  padding-left: 65px;
  display: table;
  height: 1em;
  min-height: 48px;
}
.obrodkazy .mtext {
  display: table-cell;
  vertical-align: middle;
}
.obrodkazy strong {
  font-weight: normal;
}
.obrodkazy a,
.obrodkazy a:link,
.obrodkazy a:visited {
  text-decoration: none;
}
.obrodkazy a:hover,
.obrodkazy a:focus,
.obrodkazy a:active {
  text-decoration: underline;
}

/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/
#vypisakci .dok {
  margin: 30px 0 18px;
}
#vypisakci .dok li {
  display: table;
  padding: 20px 0;
  margin: 0;
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  border-bottom: 1px solid #f4c744;
}
@media screen and (max-width: 699px) {
  #vypisakci .dok li {
    width: 100%;
  }
}
@media screen and (min-width: 700px) {
  #vypisakci .dok li {
    width: 48%;
    margin-left: 4%;
  }
  #vypisakci .dok li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
#vypisakci .dok li:nth-child(n+3) {
  border: 0;
}
@media screen and (max-width: 699px) {
  #vypisakci .dok li:nth-child(3) {
    border-bottom: 1px solid #f4c744;
  }
}
#vypisakci .dok li strong a,
#vypisakci .dok li strong a:link,
#vypisakci .dok li strong a:visited {
  color: #2b3a70;
  font-size: 1.5625rem;
  font-weight: 600;
  display: inline-block;
  padding: 12px 0 4px;
}
@media screen and (max-width: 600px) {
  #vypisakci .dok li strong a,
  #vypisakci .dok li strong a:link,
  #vypisakci .dok li strong a:visited {
    font-size: 1.375rem;
  }
}
#vypisakci .dok .datum,
#vypisakci .dok .prazdnyDatum {
  display: table-cell;
  vertical-align: top;
  width: 6em;
  text-align: center;
  margin: 0.26em 0;
  font-family: RajdhaniWeb;
  position: relative;
}
#vypisakci .dok .den,
#vypisakci .dok .mesic {
  display: block;
  line-height: 1.2;
}
#vypisakci .dok .den {
  font-size: 2.5rem;
  font-weight: bold;
  background: #ffda82;
  color: #000000;
  padding: 0.1em 0;
  border-radius: 3px 3px 0 0;
  position: relative;
  z-index: 3;
}
#vypisakci .dok .mesic {
  font-size: 1.0625rem;
  background: transparent;
  color: #444444;
  text-transform: lowercase;
  padding: 0.5em 0;
  border-radius: 0 0 3px 3px;
  position: relative;
  z-index: 3;
  border-top: 2px solid #ffffff;
}
#vypisakci .dok .datum {
  background-color: #ffffff;
}
#vypisakci .dok .denPredlozka,
#vypisakci .dok .rok,
#vypisakci .dok .den .tecka {
  position: absolute;
  left: -9999px;
  right: 9990px;
}
#vypisakci .dok .prazdnyDatum {
  background: transparent;
}
#vypisakci .dok .obsahAkce {
  display: table-cell;
  padding-left: 24px;
}

/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/
.titulodkazy2 ul, .titulodkazy2 li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.titulodkazy2 ul {
  padding: 46px 0 0;
}
.titulodkazy2 li {
  padding: 0 0 10px 3.2%;
}
@media screen and (max-width: 649px) {
  .titulodkazy2 ul {
    padding: 22px 0 16px;
  }
}
@media screen and (max-width: 600px) {
  .titulodkazy2 li a {
    font-size: 1.25rem !important;
  }
}

#obalTemata {
  width: 100%;
}

.titulodkazy h2.cvi {
  font-size: 1.875rem;
  font-weight: 600;
  color: #2c2c2c;
  background: url(images/sipkaTemata.png) no-repeat center bottom;
  padding: 0 0 13px;
  float: left;
  margin: -49px 0 0 2.65625%;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 1023px) {
  .titulodkazy h2.cvi {
    display: none;
  }
}
.titulodkazy h2.cvi span {
  display: block;
  padding: 11px 32px 12px;
  background-color: #ffda82;
}
.titulodkazy .ui {
  clear: left;
  padding: 12px 0 32px;
  display: flex;
  flex-wrap: wrap;
}
.titulodkazy li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  background-image: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 18px 2.65625%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  border-top: 2px solid white;
}
.titulodkazy li::before {
  content: normal !important;
}
@media screen and (max-width: 499px) {
  .titulodkazy li {
    width: 100%;
  }
}
@media screen and (min-width: 500px) and (max-width: 699px) {
  .titulodkazy li {
    width: 48%;
    margin-left: 4%;
  }
  .titulodkazy li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 700px) {
  .titulodkazy li {
    width: 30.6666666667%;
    margin-left: 4%;
  }
  .titulodkazy li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 501px) {
  .titulodkazy li {
    min-height: 116px;
  }
}
.titulodkazy li::before {
  content: "" !important;
  position: absolute;
  background-color: #ffda82;
  height: 0;
  transition: height 0.5s;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
}
.titulodkazy li:hover {
  border-color: #ffda82;
}
.titulodkazy li:hover a, .titulodkazy li:hover div {
  color: #1f1f1f !important;
}
.titulodkazy li:hover::before {
  height: 100%;
}
.titulodkazy li a {
  font-family: RajdhaniWeb;
  font-size: 1.5625rem;
  color: #b30717;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 701px) and (max-width: 899px) {
  .titulodkazy li a {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 500px) and (max-width: 600px) {
  .titulodkazy li a {
    font-size: 1.375rem;
  }
}
.titulodkazy li div {
  margin: 4px 0 0 0;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 1.0625rem;
  color: #4f4f4f;
  position: relative;
  z-index: 2;
}
.titulodkazy strong {
  font-weight: normal;
}

li.urd-line {
  position: relative;
}
li.urd-line strong a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}

/*****************************
* FORMULÁŘE
*****************************/
/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/
#hledani label, .titulformular label {
  line-height: 1.2;
  text-align: left;
  padding: 3px 2px;
  display: block;
}
#hledani label.label-skryty, .titulformular label.label-skryty {
  padding: 0 2px;
  position: relative;
}
#hledani label.label-skryty::after, .titulformular label.label-skryty::after {
  content: "";
  z-index: 2;
}

/****************************/
/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/
.format {
  background-color: #ffffff;
  color: #000000;
  height: 44px;
  border-radius: 0;
  line-height: 1.2142857143;
  font-size: 0.9375rem;
}
.format:-ms-input-placeholder {
  color: inherit !important;
}
.format::-ms-input-placeholder {
  color: inherit !important;
}
.format::placeholder {
  color: inherit !important;
}
.format:focus {
  border-color: #437dcc;
}

.format,
fieldset {
  border: 1px #666666 solid;
}

:not(.fvlevo) > .fvyraz #hv {
  font-size: 1.1875rem;
  height: 44px;
  border-width: 2px;
}

textarea.format {
  min-height: 88px;
}

select.format[multiple] {
  height: 132px;
}

.btn {
  border: 0;
  color: #2a2a2a;
  height: 44px;
}

.fbtn .btn {
  padding: 0 2em;
  font-size: 1rem;
}

.fbtn div, #fprihl .fbtn {
  position: relative;
  background-color: #ffda82;
  text-align: center;
}
.fbtn div .btn, #fprihl .fbtn .btn {
  position: relative;
  padding: 5px 36px;
  z-index: 2;
  display: block;
  color: #2a2a2a;
  font-family: RajdhaniWeb;
  font-size: 1.25rem;
  font-weight: 600;
}
.fbtn div .btn:hover, #fprihl .fbtn .btn:hover {
  text-decoration: underline;
}
.fbtn div::before, #fprihl .fbtn::before {
  position: absolute;
  z-index: 1;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  background-color: #F4C744;
  transition: height 0.5s;
  height: 0;
}
.fbtn div:hover::before, #fprihl .fbtn:hover::before {
  height: 100%;
}

/************************************************/
.fkont {
  margin-top: 20px;
}
.fkont form {
  padding: 21px 36px 23px 36px;
}
.fkont :not(.fvlevo) > .fvyraz label[for=hv] {
  font-size: 1rem;
}

@media screen and (min-width: 581px) {
  .nizky .fbtn, .nizky .fvpravo {
    width: 9em;
  }
}
@media screen and (max-width: 580px) {
  .nizky form {
    padding-bottom: 60px;
  }
}

.formular,
.fkont form,
.nastaveni {
  background-color: #eff0eb;
  color: #2a2a2a;
}
.formular a,
.formular a:link,
.formular a:visited,
.fkont form a,
.fkont form a:link,
.fkont form a:visited,
.nastaveni a,
.nastaveni a:link,
.nastaveni a:visited {
  color: #2a2a2a;
}

.fcesta,
.fcesta a, .fcesta a:link, .fcesta a:visited, .fcesta a:hover, selector:focus, selector:active {
  background-color: #454c5b;
  color: #ffffff;
}

/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/
.titulformular label {
  color: #eeeeea;
  font-size: 1rem;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
}
.titulformular .format {
  margin: 2px 0 16px;
  padding: 8px 15px;
  width: 100%;
  border-color: #ffffff;
  background-repeat: no-repeat;
  background-position: 0 0;
  height: 41px;
}
.titulformular img {
  position: absolute;
  right: 0;
  top: 0;
}
.titulformular .opiste {
  position: relative;
}
.titulformular .captchaformat {
  padding-right: 90px;
}
.titulformular .fbtn,
.titulformular .btn {
  clear: none;
  margin: 0;
  box-sizing: border-box;
}
.titulformular .fbtn {
  width: 7em;
  margin: 14px auto 0;
  position: relative;
  background-color: #ffda82;
  font-size: 1.25rem;
  border-radius: 3px;
}
.titulformular .fbtn::before {
  position: absolute;
  border-radius: 3px;
  z-index: 1;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  background-color: #F4C744;
  transition: height 0.5s;
  height: 0;
  border-radius: 3px;
}
.titulformular .fbtn:hover::before {
  height: 100%;
}
.titulformular .btn {
  padding: 9px 16px 8px;
  width: 100%;
  position: relative;
  z-index: 3;
  color: #2a2a2a;
  font-family: RajdhaniWeb;
  font-size: 1.25rem;
  font-weight: 600;
  background-color: transparent;
  border: 0;
  text-transform: uppercase;
  height: 43px;
}
.titulformular .btn:hover {
  text-decoration: underline;
}
.titulformular p {
  padding: 0 0 24px 0;
  margin: 0;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 1.0625rem;
  color: #eeeeea;
}
.titulformular .bezny.gdprsouhlas {
  margin-top: 0;
}

/***************
*	HLEDÁNÍ
***************/
#hledani {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  padding: 0 3%;
  position: relative;
}
@media screen and (min-width: 501px) {
  #hledani {
    top: 8px;
  }
}
@media screen and (min-width: 601px) {
  #hledani {
    top: 0;
    float: right;
    padding-left: 0;
    width: 190px;
  }
}
@media screen and (min-width: 750px) {
  #hledani {
    width: 292px;
    padding-right: 2.2%;
  }
}
@media screen and (min-width: 850px) {
  #hledani {
    width: 210px;
    padding-right: 0;
  }
}
@media screen and (min-width: 1000px) {
  #hledani {
    padding-bottom: 16px;
  }
}
@media screen and (min-width: 1151px) {
  #hledani {
    width: 200px;
  }
}
#hledani .pole {
  position: relative;
  z-index: 5;
}
#hledani label.label-skryty::after {
  background: transparent url(images/hl_prekryti.svg) repeat 0 0;
}
#hledani input:focus {
  outline: 0;
}
#hledani .format {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  height: 42px;
  border-radius: 3px;
  border: 1px solid #454c5b;
  color: #000000;
  padding: 4px 50px 4px 10px;
  background-repeat: no-repeat;
  background-position: 0 50%;
}
#hledani .btn {
  border: 0;
  position: absolute;
  z-index: 2;
  top: 1px;
  right: -2px;
  height: auto;
  border-radius: 0;
  color: #ffffff;
  background-color: #454c5b;
}

/***********************
*	TABULKY
***********************/
.blokposun {
  background-color: #eeeeee;
}

.seznam {
  border: 0;
  border-bottom: 1px solid #454c5b;
}
.seznam th a,
.seznam th a:link,
.seznam th a:visited,
.seznam th a:hover,
.seznam th a:focus,
.seznam th a:active {
  color: #ffffff;
}
.seznam td,
.seznam th {
  border: 1px #454c5b solid;
  border-width: 0 1px 0 0;
}
.seznam td:first-child,
.seznam th:first-child {
  padding-left: 36px;
}
.seznam td:last-child,
.seznam th:last-child {
  border-right: 0;
}
.seznam th {
  font-size: 1rem;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  line-height: 1.1;
  font-weight: normal;
  background-color: #454c5b;
  color: #ffffff;
  padding: 22px 10px;
  border-color: white;
}
.seznam th a,
.seznam th a:link,
.seznam th a:visited {
  color: #ffffff;
}
.seznam td {
  color: black;
  border-width: 0 1px 0 0;
}

.b1 {
  background-color: #eff0eb;
}

.b2, #isvs .hodnota {
  background-color: #ffffff;
}

/**********************
*	FOTOGALERIE
**********************/
.nahledy li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 349px) {
  .nahledy li {
    width: 100%;
  }
}
@media screen and (min-width: 350px) and (max-width: 599px) {
  .nahledy li {
    width: 49%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 600px) and (max-width: 1023px) {
  .nahledy li {
    width: 32%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  .nahledy li {
    width: 23.5%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}
.nahledy div > a {
  background: #EFF0EB;
  border: 2px transparent solid;
  border-radius: 0px;
  min-height: 200px;
}
.nahledy div > a:hover,
.nahledy div > a:focus,
.nahledy div > a:active {
  background: transparent;
  border-color: #ffda82;
}

/**********************
*	PŘEHLEDY GALERIÍ
**********************/
.galerie-2016 .ui li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 399px) {
  .galerie-2016 .ui li {
    width: 100%;
  }
}
@media screen and (min-width: 400px) and (max-width: 1023px) {
  .galerie-2016 .ui li {
    width: 49%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1024px) {
  .galerie-2016 .ui li {
    width: 32%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

/***********************
*	ZÁLOŽKY
***********************/
.zalozky {
  font-size: 1.25rem;
  line-height: 1.1;
  font-family: RajdhaniWeb;
  font-weight: normal;
  border-bottom: 6px solid #c81324;
}
.zalozky li {
  padding: 0;
  margin: 0 0.5em 0 0;
  float: left;
  position: relative;
  display: block;
}
.zalozky li.azalozka {
  background: transparent none;
}
.zalozky a {
  display: block;
  text-decoration: none;
  margin: 0;
  position: relative;
  z-index: 20;
  cursor: pointer;
  padding: 12px 36px 10px;
}
.zalozky a,
.zalozky a:link,
.zalozky a:visited {
  background-color: #454c5b;
  color: #ffffff;
}
.zalozky a:hover,
.zalozky a:focus,
.zalozky a:active {
  text-decoration: underline;
}
.zalozky .azalozka a,
.zalozky .azalozka a:link,
.zalozky .azalozka a:visited,
.zalozky .azalozka a:hover,
.zalozky .azalozka a:focus,
.zalozky .azalozka a:active {
  background-color: #c81324;
  color: #ffffff;
  text-decoration: none;
  cursor: default;
}
@media screen and (max-width: 999px) {
  .zalozky a {
    padding: 12px 18px 10px;
  }
}
@media screen and (max-width: 600px) {
  .zalozky {
    font-size: 1.125rem;
  }
}

#zahlavi2 {
  margin: 20px 0;
}

#zalozkynadpis {
  padding: 15px 0 0 0;
}

.vych_pol {
  font-size: 1rem;
  text-align: right;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}
.vych_pol strong {
  font-weight: normal;
}

a[id*=k0] {
  height: 0;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
  color: transparent !important;
}

/****************************
*	S VYPNUTÝM JS NA TITULCE
****************************/
.bezjs {
  padding: 20px 0 15px 0;
}
.bezjs .inline li::before {
  color: #404040;
}

#keskryti {
  display: inline;
}

/****************************
*	SE ZAPNTÝM JS NA TITULCE
****************************/
#titul .zalozky {
  font-size: 1.875rem;
  line-height: 1.1;
  font-family: RajdhaniWeb;
  position: relative;
  background-color: #454c5b;
  border-bottom: 8px solid #c81324;
  padding-top: 28px;
}
#titul .zalozky::before, #titul .zalozky::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: -8px;
  width: 360px;
  background-color: #454c5b;
  border-bottom: 8px solid #c81324;
}
#titul .zalozky::before {
  right: 100%;
}
#titul .zalozky::after {
  left: 100%;
}
#titul .zalozky .inline li::before {
  content: normal;
}
#titul .zalozky li {
  margin: 0 4px 6px;
  border: 2px solid white;
  border-bottom: 0;
}
#titul .zalozky li:hover {
  border-color: #ffda82;
}
#titul .zalozky a {
  padding: 14px 32px;
  font-weight: 600;
  margin-bottom: -6px;
  position: relative;
  z-index: 3;
}
#titul .zalozky a,
#titul .zalozky a:link,
#titul .zalozky a:visited {
  background-color: #454c5b;
  color: #ffffff;
}
#titul .zalozky a:hover,
#titul .zalozky a:focus,
#titul .zalozky a:active {
  color: #ffda82;
  text-decoration: none;
}
#titul .zalozky .azalozka {
  border: 0;
}
#titul .zalozky .azalozka a,
#titul .zalozky .azalozka a:link,
#titul .zalozky .azalozka a:visited,
#titul .zalozky .azalozka a:hover,
#titul .zalozky .azalozka a:focus,
#titul .zalozky .azalozka a:active {
  padding: 16px 32px 14px;
  background-color: #c81324;
  color: #ffffff;
  text-decoration: none;
}
@media screen and (max-width: 999px) {
  #titul .zalozky {
    padding-top: 22px;
    font-size: 1.5rem;
  }
  #titul .zalozky a {
    padding: 14px 24px;
  }
}
@media screen and (max-width: 799px) {
  #titul .zalozky {
    padding-top: 16px;
    font-size: 1.25rem;
  }
  #titul .zalozky a {
    padding: 14px 16px;
  }
}

@media screen and (max-width: 649px) {
  #linkynakotvy {
    display: none;
  }
  #titul .poz h2.cist {
    position: static;
    width: auto;
    height: auto;
  }
  #titul .poz .skryty {
    display: block;
  }
}
/******************
* MODULY
******************/
#uvodtitul {
  overflow: hidden;
  margin: 0 0 30px 0;
}

#upozorneni {
  text-align: left;
  background-color: #454c5b;
  position: absolute;
  right: 0;
  bottom: 8px;
  width: 100%;
  max-width: 800px;
  color: white;
  font-size: 1.0625rem;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  display: table;
}
#upozorneni p, #upozorneni h2.cvi {
  box-sizing: border-box;
  display: table-cell;
  vertical-align: middle;
  height: 59px;
  padding: 6px 32px 5px;
}
#upozorneni p {
  background: url(images/sipkaUpozorneni.png) no-repeat left center;
}
#upozorneni h2.cvi {
  font-size: 1.5625rem;
  font-weight: 600;
  background-color: #c81324;
  color: white;
  white-space: nowrap;
}
#upozorneni a {
  color: white;
}
#upozorneni a:hover {
  text-decoration: none;
}
@media screen and (max-width: 799px) {
  #upozorneni {
    position: static;
  }
}
@media screen and (max-width: 600px) {
  #upozorneni h2.cvi, #upozorneni p {
    display: block;
    padding: 16px 24px 15px;
    height: auto;
    min-height: 59px;
  }
  #upozorneni p {
    background: transparent;
    min-height: auto;
  }
  #upozorneni h2.cvi {
    margin: 0;
  }
}

#kalendarAkci .editor {
  margin-top: 40px;
  margin-bottom: 40px;
}

#mbannery1, #mbannery2 {
  font-size: 0.875rem;
  padding: 40px 0;
  margin: 0;
  color: #000000;
  background: #ffffff;
}
#mbannery1 .editor, #mbannery2 .editor {
  text-align: center;
}
#mbannery1 .editor a,
#mbannery1 .editor a:link,
#mbannery1 .editor a:visited,
#mbannery1 .editor a:hover,
#mbannery1 .editor a:focus,
#mbannery1 .editor a:active, #mbannery2 .editor a,
#mbannery2 .editor a:link,
#mbannery2 .editor a:visited,
#mbannery2 .editor a:hover,
#mbannery2 .editor a:focus,
#mbannery2 .editor a:active {
  color: #000000 !important;
}
#mbannery1 li, #mbannery2 li {
  margin-top: 0;
  margin-bottom: 0;
}

#mbannery1 {
  padding: 55px 0;
}

#mbannery2 li:nth-child(2) img {
  max-height: 125px;
  width: auto !important;
  padding: 0 16px;
}

#jmeniny {
  padding: 6px 12px 2px 15px;
  text-align: left;
}
#jmeniny * {
  display: inline;
}

#obalKontakt #kontakt {
  max-width: 16em;
  margin: 0 auto;
  font-size: 1.0625rem;
  line-height: 1.4;
}
#obalKontakt address {
  display: block;
  font-style: normal;
  margin: 0 0 15px 0;
}
#obalKontakt address .telefon {
  display: inline-block;
  margin-top: 32px;
}
#obalKontakt strong {
  font-weight: bold;
}
#obalKontakt .dalsi a {
  display: block;
  color: #2a2a2a;
  font-family: RajdhaniWeb;
  font-size: 1.25rem;
  position: relative;
  background-color: #ffda82;
  border-radius: 3px;
  text-decoration: none;
  width: 11.25em;
  float: left;
  margin: 20px 0 32px;
  text-transform: uppercase;
}
#obalKontakt .dalsi a span {
  position: relative;
  padding: 8px 10px;
  z-index: 2;
  display: block;
  font-weight: 600;
  text-align: center;
}
#obalKontakt .dalsi a::before {
  position: absolute;
  z-index: 1;
  content: "";
  top: 0;
  right: 0;
  left: 0;
  background-color: #F4C744;
  transition: height 0.5s;
  height: 0;
  border-radius: 3px;
}
#obalKontakt .dalsi a:hover {
  text-decoration: underline;
}
#obalKontakt .dalsi a:hover::before {
  height: 100%;
}

#dotazy {
  display: block;
  text-align: center;
  padding: 10px 16px;
  box-sizing: border-box;
  border: 1px solid #ffda82;
  color: #ffda82 !important;
  text-transform: uppercase;
  margin: 30px 0;
  text-decoration: none;
}
#dotazy:hover {
  color: white !important;
  border-color: white;
}

#anketa p {
  padding: 0;
  margin: 0 0 1em 0;
}
#anketa ul {
  list-style-type: none;
  padding: 0;
  margin: 8px 0 5px 0;
}
#anketa ul li {
  margin-bottom: 8px;
}
#anketa .hlas {
  margin-top: 3px;
  z-index: 1;
}
#anketa .hlas div {
  height: 12px;
  background-color: #460432;
}

#pocitadlo {
  margin: 15px 0 0 0;
  text-align: center;
  padding-bottom: 15px;
}
#pocitadlo ul.inline {
  padding-left: 0;
  display: inline;
}
#pocitadlo ul.inline li span {
  margin: 0 0 0 2px;
}

#obalKalendar {
  background-color: #f7f7f1;
}
#obalKalendar .centrovany {
  max-width: 1212px;
}
#obalKalendar .obaldalsi {
  margin-top: 0;
  padding: 26px 0;
  border-top: 2px solid #f4c744;
}
#obalKalendar .obaldalsi .dalsi {
  border: 0;
}
#obalKalendar .obaldalsi .dalsi::after {
  background-color: #F4C744;
}

#kalendarNadpis {
  background-color: #454c5b;
  border-bottom: 8px solid #ffda82;
  padding-top: 19px;
}
#kalendarNadpis h2.cvi {
  color: #2c2c2c;
  background-color: #ffda82;
  float: left;
  font-weight: 600;
  font-size: 1.875rem;
  padding: 20px 32px 13px;
  margin: 0;
}
#kalendarNadpis #kalendarDatum {
  float: right;
  color: white;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 1.0625rem;
  padding: 34px 0 11px 16px;
}
#kalendarNadpis #kalendarDatum p {
  margin: 0;
}

/*******************************
* KALENDÁŘE
*******************************/
/* #kalakci {
	p {
		clear: both;
	} */
#kalakci {
  margin-bottom: 30px;
}

.mesice {
  display: table;
  width: 100%;
  line-height: 0.8;
  table-layout: fixed;
  border-spacing: 1px 0;
  margin-bottom: 10px;
}
.mesice + p {
  margin: 20px 0;
}
.mesice a {
  display: table-cell;
  padding: 1em 0;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
}
.mesice a.sipka {
  width: 14.2857142857%;
  font-family: RajdhaniWeb;
  position: relative;
}
.mesice a,
.mesice a:link,
.mesice a:visited {
  color: #ffffff;
  background-color: #454c5b;
}

.calcells th {
  font-weight: 700;
  color: #2a2a2a;
  background-color: #ffda82;
  border-bottom: 10px transparent solid;
  background-clip: padding-box;
}
.calcells th,
.calcells td {
  padding: 7px 0;
}
.calcells td > a {
  padding: 7px 0;
  margin: -7px 0;
}
.calcells td,
.calcells td > a, .calcells td a:link, .calcells td a:visited {
  background-color: #454c5b;
  color: #ffffff;
}
.calcells td.notmnth {
  background-color: #ffda82;
  color: #2a2a2a;
}
.calcells td.notmnth > a, .calcells td.notmnth a:link, .calcells td.notmnth a:visited {
  background-color: #ffda82;
  color: #2a2a2a;
}
.calcells td.curdate {
  border: 2px #ffffff solid;
  padding: 0;
}
.calcells td.curdate > a {
  padding: 5px 0;
  margin: -2px 0;
}
.calcells td.wkday > a:hover, .calcells td.wkday a:focus, .calcells td.wkday a:active, .calcells td.notmnth > a:hover, .calcells td.notmnth a:focus, .calcells td.notmnth a:active {
  background-color: #ffffff;
  color: #000000;
}

#stranka .calcells th {
  color: #2a2a2a;
  background-color: #ffda82;
  font-weight: 700;
}
#stranka .calcells td.wkday,
#stranka .calcells td.wkday > a, #stranka .calcells td.wkday a:link, #stranka .calcells td.wkday a:visited, #stranka .calcells td.wkend,
#stranka .calcells td.wkend > a, #stranka .calcells td.wkend a:link, #stranka .calcells td.wkend a:visited {
  background-color: #454c5b;
  color: #ffffff;
}
#stranka .calcells td > a, #stranka .calcells td a:link, #stranka .calcells td a:visited {
  background-color: #ffda82;
  color: #2a2a2a;
}
#stranka .calcells td.curdate {
  border: 2px #ffffff solid;
  border-color: #ffffff;
}
#stranka .calcells td.wkday > a:hover, #stranka .calcells td.wkday a:focus, #stranka .calcells td.wkday a:active, #stranka .calcells td.wkend > a:hover, #stranka .calcells td.wkend a:focus, #stranka .calcells td.wkend a:active, #stranka .calcells td.notmnth > a:hover, #stranka .calcells td.notmnth a:focus, #stranka .calcells td.notmnth a:active {
  background-color: #ffffff;
  color: #000000;
}
#stranka .calcells td.cell_selected,
#stranka .calcells td.cell_selected > selector-link-all {
  background-color: #b0030c;
  color: #ffffff;
}

/*****************************************
* MIXINY A PROMĚNNÉ PRO TABULKOVÉ MENU
*****************************************/
/****************************************/
/******************************
* HORIZONTÁLNÍ MENU A VÝBĚRY
******************************/
#obalmenu1 {
  position: relative;
  z-index: 2;
  clear: both;
  background-color: #454c5b;
  border-top: 1px solid #959595;
  border-bottom: 1px solid #dbd7d4;
}
#obalmenu1 .centrovany {
  width: 100%;
}

#menu1 ul {
  text-align: center;
  margin: 14px 0;
}
#menu1 li {
  font-size: 1.5625rem;
  line-height: 1.1;
  display: inline-block;
  font-weight: 600;
  margin: 6px 0;
  padding: 0 12px 0 8px;
  border-right: 1px solid #ffda82;
}
#menu1 li:last-child {
  border: 0;
}
#menu1 li.akt a,
#menu1 li.akt a:link,
#menu1 li.akt a:visited {
  color: #ffda82;
}
#menu1 li a {
  display: block;
  font-family: RajdhaniWeb;
  padding: 14px 16px;
}
@media screen and (min-width: 600px) {
  #menu1 li a {
    padding: 5px;
  }
}
@media screen and (min-width: 1100px) {
  #menu1 li a {
    padding: 0 20px;
  }
}
#menu1 li a,
#menu1 li a:link,
#menu1 li a:visited {
  text-decoration: none;
  color: #ffffff;
  transition: color 0.5s;
}
#menu1 li a:hover,
#menu1 li a:focus,
#menu1 li a:active {
  color: #ffda82;
}
#menu1 li #osmakth,
#menu1 li #osmakth:link,
#menu1 li #osmakth:visited,
#menu1 li #osmakth:hover,
#menu1 li #osmakth:focus,
#menu1 li #osmakth:active {
  cursor: text;
  text-decoration: none;
}
@media screen and (max-width: 1099px) {
  #menu1 li {
    font-size: 1.375rem;
  }
}
@media screen and (max-width: 799px) {
  #menu1 li {
    font-size: 1.1875rem;
  }
}
@media screen and (max-width: 599px) {
  #menu1 ul {
    margin: 0;
  }
  #menu1 li {
    margin: 0;
    font-size: 1.375rem;
    display: block;
    border-right: 0;
    border-bottom: 1px solid #DBD7D4;
  }
}

/*************************
* VÝBĚRY MENU
*************************/
#stranka.noleftpanel #obalcelek #celek.centrovany {
  max-width: 1234px;
  margin: 0 auto;
  width: 92%;
}
#stranka.noleftpanel #telo #stred {
  direction: ltr;
  display: block;
  width: 100%;
  float: none;
  clear: both;
  margin: 0 auto;
  padding: 0 !important;
}
#stranka.noleftpanel #telo #stred #zahlavi h2.cvi {
  margin: 0;
  padding: 42px 0 28px;
}
#stranka.noleftpanel #menu-start {
  display: none;
}
#stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui {
  margin: 0 0 44px;
}
#stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
}
@media screen and (max-width: 399px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 100%;
  }
}
@media screen and (min-width: 400px) and (max-width: 699px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 47.5%;
    margin-left: 5%;
  }
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 700px) and (max-width: 1099px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 30.8%;
    margin-left: 3.8%;
  }
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1100px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 22.15%;
    margin-left: 3.8%;
  }
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}
#stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li strong a {
  font-size: 1.5rem;
}
#stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li div {
  font-size: 1rem;
}

#osnova * {
  font-style: normal;
}

.mapa-stranek-2016 h3.souvisejiciodkazy {
  display: none;
}
.mapa-stranek-2016 .editor {
  margin-top: 30px;
  max-width: 100%;
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 30px;
  /****************
  * ZÁKLADNÍ STYLY
  ****************/
  float: left;
  /****************
  * /ZÁKLADNÍ STYLY
  ****************/
  padding: 10px 0 0;
  border-top: 3px solid #ebebeb;
  position: relative;
}
@media screen and (max-width: 399px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 100%;
  }
}
@media screen and (min-width: 400px) and (max-width: 1099px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 47.5%;
    margin-left: 5%;
  }
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}
@media screen and (min-width: 1100px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 30.8%;
    margin-left: 3.8%;
  }
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui li:hover {
  border-color: #ffda82;
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui li strong a {
  font-size: 1.25rem;
  font-family: RajdhaniWeb;
  color: #c81324;
  text-decoration: none;
  font-weight: 600;
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui li strong a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui li div {
  color: #4f4f4f;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  font-size: 0.9375rem;
  font-weight: 300;
  margin-top: 8px;
}
.mapa-stranek-2016 .odkazy.souvisejici ul.ui li:last-child {
  margin-bottom: 42px;
}
.mapa-stranek-2016 .pataprvku, .mapa-stranek-2016 .popis {
  margin: 30px 0;
  box-sizing: border-box;
  font-size: 1.0625rem;
  font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
  padding: 0;
  max-width: 842px;
  color: #4f4f4f;
}
.mapa-stranek-2016 .pataprvku {
  margin: 0;
  border-top: 7px solid #eff0eb;
  padding: 30px 0 40px;
}
@media screen and (max-width: 999px) {
  .mapa-stranek-2016 ul.ui li strong a {
    font-size: 1.375rem !important;
  }
}
@media screen and (max-width: 599px) {
  .mapa-stranek-2016 ul.ui li strong a {
    font-size: 1.25rem !important;
  }
}

/*********************
* MENU VE SLOUPCI
*********************/
.menu-controls,
#menu-start {
  display: none;
}

.nadmenu,
#nadmenu {
  display: none;
}

/**********************
* MOBILNÍ MENU
**********************/
#menu {
  background-color: #454c5b;
  padding: 20px;
}
#menu > ul a {
  padding: 5px 20px;
  margin-left: -20px;
  display: block;
}
#menu > ul a,
#menu > ul a:link,
#menu > ul a:visited {
  color: #ffffff;
}
#menu > ul #osmakt,
#menu > ul #osmakt:link,
#menu > ul #osmakt:visited,
#menu > ul #osmakt:hover,
#menu > ul #osmakt:focus,
#menu > ul #osmakt:active {
  background-color: transparent;
  color: #ffda82;
  text-decoration: none;
}
#menu .akt a {
  color: #ffda82 !important;
  text-decoration: none;
}
#menu ul {
  padding-left: 20px;
}

@media screen and (max-width: 800px) {
  #menu-start {
    display: block;
    clear: both;
    text-align: center;
  }
  #menu-start a {
    background: #454c5b;
    display: block;
    padding: 15px 0;
  }
  #menu-start a::before {
    display: inline-block;
    vertical-align: middle;
    margin: -0.15em 0.75em 0 0;
    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='22' viewBox='0 0 30 22'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' clip-rule='evenodd' d='M29 22h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1z'/%3E%3C/svg%3E");
  }
  #menu-start a,
  #menu-start a:link,
  #menu-start a:visited {
    color: #ffffff;
    text-decoration: none;
  }
  .no-scroll {
    height: 100%;
    touch-action: none;
    overflow: hidden;
    pointer-events: none;
    -webkit-overflow-scrolling: auto;
  }
  .no-scroll body {
    height: 100%;
    touch-action: none;
    overflow: hidden;
  }
  #menu.menu-initialised {
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 200;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-width: 400px;
    height: 100vh;
    padding: 70px 20px 20px 0;
    transition: transform 0.3s ease-out;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
    -webkit-transition: -webkit-transform 0.3s ease-out;
  }
  [data-env=Safari] #menu.menu-initialised {
    max-width: 100%;
  }
  #menu.menu-initialised.menu-active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  #menu.menu-initialised > ul {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 0 0 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 101;
  }
  #menu.menu-initialised > ul > li {
    margin-bottom: 15px;
  }
  #menu.menu-initialised > ul > li:last-child {
    margin-bottom: 80px;
  }
  #menu.menu-initialised ul {
    list-style-type: none;
  }
  #menu.menu-initialised .menu-controls {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    display: block;
    height: 50px;
    z-index: 100;
    background-color: #2b3a70;
    color: #ffffff;
  }
  #menu.menu-initialised .menu-controls .menu-control-back,
  #menu.menu-initialised .menu-controls .menu-control-title {
    display: block;
  }
  #menu.menu-initialised .menu-controls .menu-control-back {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 0;
    top: 0;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' d='M13.569 10.994l8.162 8.165c.355.358.355.936 0 1.294l-1.28 1.279c-.357.358-.935.358-1.293 0l-8.16-8.166-8.162 8.166c-.357.358-.935.358-1.292 0l-1.278-1.279c-.357-.358-.357-.936 0-1.294l8.162-8.165-8.152-8.154c-.357-.357-.357-.935 0-1.292l1.279-1.28c.356-.357.935-.357 1.292 0l8.151 8.154 8.152-8.154c.357-.357.935-.357 1.293 0l1.276 1.28c.359.357.359.935 0 1.292l-8.15 8.154z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 22px 22px;
  }
  #menu.menu-initialised .menu-controls .menu-control-title {
    padding: 0 20px;
    line-height: 2.2;
    border-right: 70px transparent solid;
    white-space: nowrap;
    font-size: 1.25rem;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #menu.menu-initialised .menu-indicator {
    display: none;
  }
}
/***********************
* DESKTOPOVÉ MENU
***********************/
@keyframes sipkaMenu {
  from {
    background-position: 10px 50%;
  }
  50% {
    background-position: 0 50%;
  }
  to {
    background-position: 10px 50%;
  }
}
@media screen and (min-width: 801px) {
  .nadmenu {
    display: block;
  }
  #nadmenu {
    font-size: 0.875rem;
    display: none;
    font-weight: normal;
    padding: 0;
    white-space: nowrap;
  }
  #menu .nadmenu a {
    display: inline-block;
    font-size: 1.875rem;
    font-family: RajdhaniWeb;
    font-weight: 600;
    color: #ffda82;
    margin: 25px 0 5px 20px;
    padding: 12px 0 12px 40px;
    text-decoration: none;
    position: relative;
    z-index: 10;
    background: url(images/sipkaMenuZpet.png) no-repeat 10px 50%;
    animation-name: sipkaMenu;
    animation-duration: 1.5s;
    animation-iteration-count: 0;
    animation-timing-function: ease;
  }
  #menu .nadmenu a:hover {
    text-decoration: underline;
    animation-iteration-count: infinite;
  }
  #menu {
    background-color: #454c5b;
    padding: 0 0 40px 0;
  }
  #menu .menu-item-collapsed > ul {
    display: none;
  }
  #menu .menu-item-expanded > a .menu-indicator {
    transform: rotate(225deg);
  }
  #menu .menu-item-expanded > ul {
    display: block;
  }
  #menu .menu {
    font-size: 1.125rem;
    font-family: RajdhaniWeb;
    line-height: 1.2;
    padding: 0 6% 5px 8%;
  }
  #menu .menu li:first-child {
    border-top: 1px solid #ffda82;
    padding-top: 16px;
  }
  #menu .menu ul {
    text-transform: none;
  }
  #menu .menu a,
  #menu .menu a:link,
  #menu .menu a:visited {
    color: #ffffff;
    text-decoration: none;
  }
  #menu .menu a:hover,
  #menu .menu a:focus,
  #menu .menu a:active {
    color: #ffda82;
  }
  #menu .menu #osmakt,
  #menu .menu #osmakt:link,
  #menu .menu #osmakt:visited,
  #menu .menu #osmakt:hover,
  #menu .menu #osmakt:focus,
  #menu .menu #osmakt:active {
    font-weight: bold;
    background-color: transparent;
    text-decoration: none;
    cursor: default;
    border: 0;
  }
  #menu .menu a {
    padding: 6px 0;
    display: block;
    position: relative;
    z-index: 10;
    margin-left: 0;
    text-decoration: none;
    font-size: 1.25rem;
    font-family: RajdhaniWeb;
    font-weight: 600;
  }
  #menu .menu .menu-indicator {
    width: 16px;
    height: 16px;
    overflow: hidden;
    position: absolute;
    top: 0.85em;
    left: -27px;
    z-index: 10;
    -webkit-transition: -webkit-transform 0.25s;
    transition: -ms-transform 0.25s;
    transition: transform 0.25s;
  }
  #menu .menu .menu-indicator img {
    margin: 0;
    cursor: pointer;
    font-size: 0.875rem;
  }
  #menu .menu ul {
    font-size: 1rem;
    font-family: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
    margin: 0 0 0 -40px;
    padding: 2px 0 12px 0;
  }
  #menu .menu ul a {
    padding: 1px 15px 0 40px;
    line-height: 1.5;
  }
  #menu .menu ul .menu-indicator {
    left: 13px;
    top: 0.23em;
  }
  #menu .menu ul ul {
    margin: 0;
    padding: 0 0 0 24px;
    position: static;
  }
  #menu li {
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
  }
  #menu li li {
    border: 0;
    font-weight: normal;
  }
}
#google_translate_element {
  display: inline-block;
  position: relative;
  top: -1px;
}
#google_translate_element.pole {
  background-color: #ffffff;
}
#google_translate_element .goog-te-gadget-simple {
  border: 0;
  width: 100%;
  padding-bottom: 1px;
  background-color: transparent;
}
#google_translate_element .goog-te-gadget-simple span {
  display: none;
}
#google_translate_element .goog-te-gadget-simple, #google_translate_element .goog-te-gadget-simple * {
  font-size: 1rem !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup][href="#"] {
  margin-right: 0;
  display: inline-block;
  text-decoration: none !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup][href="#"] span.gsp1 {
  padding: 0 3px;
  display: none;
  color: #000000;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup][href="#"] span.gsp2 {
  padding-right: 0;
  border-left: 0 !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup][href="#"] span.gsp3 {
  background: none;
  display: none;
  color: #000000 !important;
}
#google_translate_element .goog-te-gadget-simple [aria-haspopup][href="#"] img {
  display: none;
}

@media screen and (max-width: 599px) {
  #google_translate_element,
  #goog-gt-tt,
  #goog-gt-tt + div > object,
  iframe.goog-te-menu-frame,
  iframe.goog-te-banner-frame {
    display: none !important;
  }
}