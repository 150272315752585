// Sablona - vsechny stranky + FTB

@include font($font);

$font-base-px: 16px;
$font-base: get-base-font-size-percentage($font-base-px) !global;

html {
	line-height: ratio($font-base-px, 22px, float);
	font-family: $sans-serif;
	font-size: 18px;

	@include bp("> 600px") {
		font-size: $font-base-px;
	}
}

body.editor {
	background-color: #ffffff;
}

.editor {
	h3 {
		font-weight: normal;
		margin: 10px 0;
		padding: 0;
		font-family: $font;
		line-height: 1.2;
	}
}

body, 
.editor {
	font-size: 1em;
}

body,
.automat {
	color: $color-base;
}

@include link {
	color: $color-base-link;
	text-decoration: underline;
}

@include link-over {
	text-decoration: none;
}

hr,
hr.oddelovac {
	color: #dddddd;
	background-color: #dddddd;
}

body,
input,
button,
textarea,
select {
	font-family: $sans-serif;
}

.zvyrazneni,
.vystraha,
.strlistovani strong {
	color: #BD013E;
	background-color: #ffffff;
	padding: 0 3px;
}

.vystraha {
	border-radius: 3px;
}

.zvyrazneni2 {
	background-color: #303030;
	color: #ffffff;
	padding: 0 2px;
}

strong.zprava {
	font-size: 1.1em;
}