
#google_translate_element {
	display: inline-block;
	position: relative;
	top: -1px;

	&.pole {
		background-color: #ffffff;
	}

	.goog-te-gadget-simple {
		border: 0;
		width: 100%;
		padding-bottom: 1px;
		background-color: transparent;
		
		span {
			display:none;
		}
			
		&,
		& * {
			font-size: rem(16px) !important;
		}

		[aria-haspopup][href='#'] {
			margin-right: 0;
			display: inline-block;
			text-decoration: none!important;

			span {
				&.gsp1 {
					padding: 0 3px;
					display: none;
					color: #000000;
				}
				
				&.gsp2 {
					padding-right: 0;
					border-left: 0!important;
				}
				
				&.gsp3 {
					background: none;
					display: none;
					color: #000000!important;
				}
			}

			img {
				display: none;
			}
		}
	}
}

@include bp("< 600px") {
	#google_translate_element,
	#goog-gt-tt,
	#goog-gt-tt + div > object,
	iframe.goog-te-menu-frame,
	iframe.goog-te-banner-frame {
		display: none!important;
	}
}