
/*******************
* NADPISY
*******************/

h1.cvi {
  font-size: rem(20px);
  padding: 0;
  line-height: 1.1;
  margin: 0 20px;
  font-family: $font;
  font-weight: 600;
  color: #666666;

  strong {
    font-size: 2.6rem;
    font-weight: 600;
    display: block;
    color: #484848;
    letter-spacing: -0.025em;
    text-transform: uppercase;

    @include bp("< 1220px"){
    	font-size: rem(34px);
    }

    @include bp("< 650px"){
    	font-size: rem(30px);
    }

    @include bp("<= 500px"){
    	font-size: rem(34px);
    }
  }

	@include bp(">= 500px", "< 580px"){
		margin: 0;
	}

  span{
  	display: block;
  	margin: -2px 0 -3px 3px;
  }
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
#prohlaseni h3,
h4 {
	font-family: $font;
}

.poz h2.cist {
	font-size: rem(40px);
	color: $barR;
	font-weight: 600;
	margin: 32px 0 0;
	padding: 0;
	line-height: 1.2;
	text-align: center;
}

#zahlavi h2.cvi {
  margin: 20px 0 30px 0;
  padding: 0;
}

// Hlavní nadpisy na podstránce
#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
  font-size: rem(35px);
  color: #444444;
  font-weight: 700;
}

h3 {
  .editor &,
	&.cvi,
	&.ud,
	#prohlaseni & {
		font-size: rem($h3-size);
		font-weight: 700;
		margin: 25px 0 5px 0;
		padding: 8px 20px 4px 0;
		line-height: 1.2;
	}
}

h4 {
	font-size: rem($h4-size);
	line-height: 1.2;

	&.ud{
		font-weight: 600;	
	}
}

h4.urd-list {
	font-size: rem(25px);
	padding-top: .75em;
	float: none;
	border-top: 3px #cccccc solid;
	padding-right: 7em;

	+ .udz.urd-list {
		margin-top: -1.6em;
		margin-bottom: 2em;
		float: none;
		text-align: right;

	}

	@include bp("< 1024px") {
		padding-right: 0;
		
		+ .udz.urd-list {
				margin-top: 1em;
		}
	}

}

h5 {
	font-size: 1.1em;
}