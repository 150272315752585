
/***********************
*	TABULKY
***********************/

.blokposun {
	background-color: #eeeeee;
}


.seznam {
	border: 0;
	border-bottom: 1px solid $barG;
	
	@include link-all("th a") {
		color: #ffffff;
	}

	td,
	th {
		border: 1px $barG solid;
		border-width: 0 1px 0 0;

		&:first-child {
			padding-left: if(nth($form-padding, 2) == 0, 15px, nth($form-padding, 2));
		}

		&:last-child {
			border-right: 0;
		}
	}

	th {
		font-size: rem(16px);
		font-family: $font2;
		line-height: 1.1;
		font-weight: normal;
		background-color: $th-background;
		color: $th-text;
		padding: 22px 10px;
		border-color: white;

		@include link {
			color: $th-text;
		}
	}

	td {
		color: black;
		border-width: 0 1px 0 0;
	}

	// .b1 td {
	// 	border-color: $b2-background;
	// } 

	// .b2 td {
	// 	border-color: $b1-background;
	// } 
}

.b1 {
	background-color: $b1-background;
} 

.b2 {
	background-color: $b2-background;
}