/*************************
* GLOBÁLNÍ PROMĚNNÉ
*************************/

$web_bitmask: 1 !global; // Bitová maska pro nastavení stylů - více v _bity.scss

$sans-serif: Arial, "Arial CE", Helvetica, "Helvetica CE", sans-serif;
$font: RajdhaniWeb;
$font2: $sans-serif;

$color-base: #444444; //základní barva textu
$color-base-link: #4f4f4f; //základní barva odkazů
$color-main: #460432; //

/************************/

$barG: #454c5b;
$barR: #c81324;
$barZ: #ffda82;
$barB: #2b3a70;

/*************************
* PROMĚNNÉ KOMPONENT
*************************/

/** TYPOGRAFIE **/

$h2-size: 35px;
$h3-size: 28px;
$h4-size: 22px;

/** FORMULÁŘE **/

$form-background: #eff0eb; // pozadí formulářů
$form-padding: 21px 36px 23px 36px; // odsazení formulářů
$form-text: #2a2a2a; // barva písma ve formuláři
$form-link: #2a2a2a; // barva odkazů ve formuláři
$format-height: 44px; // výška běžných inputů a selectů ve formulářích
$format-main-height: 44px; // výška hlavního inputu ve formuláři
$format-border: #666666; // barva okrajů inputů
$format-border-focus: #437dcc; // barva okrajů inputů
$btn-height: 44px; // výška tlačítek ve formulářích
$btn-background: $barZ; // pozadí tlačítek
$btn-border: 0; // border tlačítek
$btn-color: #2a2a2a; // barva textu tlačítek

/** TABULKY **/

$b2-background: #ffffff;
$b1-background: #eff0eb;
$th-background: $barG;
$th-text: #ffffff;

/** SEZNAMY **/

$document-link-color: $barR; // barva odkazů ve výpisu dokumentů
$list-top-level-link-color: $barR; // barva velkých odkazů v první úrovni seznamů

/** AKCE **/

$event-day-size: 40px; // velikost písma v cedulce - den
$event-month-size: 17px; // velikost písma v cedulce - den
$event-link-color: $barB; // barva odkazů ve výpisu akcí
$event-day-background: $barZ; // pozadí dne cedulky v kalendáři
$event-day-text: #000000; // text dne cedulky v kalendáři
$event-month-background: #ffffff; // pozadí měsíce cedulky v kalendáři
$event-month-text: #444444; // text měsíce cedulky v kalendáři

/** KALENDÁŘE **/

$cal-cell-padding: 7px; //padding buňky - top a bottom

$cal-title-month-background: $barG; //pozadí buňky v měsíci
$cal-title-month-text: #ffffff; //text buňky v měsíci
$cal-title-notmonth-background: $barZ; //pozadí buňky mimo měsíc
$cal-title-notmonth-text: #2a2a2a; //text buňky mimo měsíc
$cal-title-current-border: #ffffff; //barva rámečku aktuálního dne

$cal-hover-background: #ffffff; //pozadí buňky po najetí myši
$cal-hover-text: #000000; //text buňky po najetí myši

$cal-sub-month-background: $cal-title-month-background;
$cal-sub-month-text: $cal-title-month-text;
$cal-sub-notmonth-background: $cal-title-notmonth-background;
$cal-sub-notmonth-text: $cal-title-notmonth-text;
$cal-sub-current-border: $cal-title-current-border;
$cal-sub-selected-background: #b0030c;
$cal-sub-selected-text: #ffffff;

/** MENU **/

$menu-tablet-size: 800px; // hranice mezi vyjížděcím/statickým menu
$menu-bg: $barG; // pozadí menu
$menu-link-color: #ffffff; // barva odkazů a písma v menu
$menu-topbar-bg: $barB; // barva horní lišty značící aktuální umístění
$menu-topbar-text: #ffffff; // text a ikony v horní liště
$menu-trigger-bg: $barG; // barva pozadí tlačítka pro otevření menu
$menu-trigger-text: #ffffff; // text v tlačítku
$menu-active-bg: transparent; // pozadí aktivní položky mobilního menu
$menu-active-text: $barZ; // text aktivní položky mobilního menu

/** ZÁLOŽKY **/

$tab-background: $barG; //pozadí záložky
$tab-text: #ffffff; //text záložky
$tab-selected-background: $barR; //pozadí aktivní záložky
$tab-selected-text: #ffffff; //text aktivní záložky

$tab-sub-background: $tab-background; //pozadí záložky - podstránky
$tab-sub-text: $tab-text; //text záložky - podstránky
$tab-sub-selected-background: $tab-selected-background; //pozadí aktivní záložky - podstránky
$tab-sub-selected-text: $tab-selected-text; //text aktivní záložky - podstránky

/************************/

/***********************************************************
* PROMĚNNÉ BREAKPOINTŮ PRO SNADNĚJŠÍ ZADÁVÁNÍ MIXINU "BP"
***********************************************************/

$v400: "> 400px";
$v500: "> 500px";
$v600: "> 600px";
$v700: "> 700px";
$v800: "> 800px";
$v900: "> 900px";
$v1024: "> 1024px";
$v1280: "> 1280px";

$v400v: ">= 400px";
$v500v: ">= 500px";
$v600v: ">= 600px";
$v700v: ">= 700px";
$v800v: ">= 800px";
$v900v: ">= 900px";
$v1024v: ">= 1024px";
$v1280v: ">= 1280px";

$m400: "< 400px";
$m500: "< 500px";
$m600: "< 600px";
$m700: "< 700px";
$m800: "< 800px";
$m900: "< 900px";
$m1024: "< 1024px";
$m1280: "< 1280px";

$m400v: "<= 400px";
$m500v: "<= 500px";
$m600v: "<= 600px";
$m700v: "<= 700px";
$m800v: "<= 800px";
$m900v: "<= 900px";
$m1024v: "<= 1024px";
$m1280v: "<= 1280px";

/**********************************************************/

$_wh: #ffffff;
$_bl: #000000;
