
/*************************
* ZÁHLAVÍ
*************************/


/*************************
* HLAVA
*************************/
 
#obalhlava {
	position: relative;
	z-index: 13;
	background-color: #f3f3ef;
	color: #2a2a2a;

	@include link {
		color: #2a2a2a;
	}
}

#hlava {
	@extend %sf;
	clear: both;
	position: relative;
	z-index: 3;
}

#znak {
	margin: 0;
	position: relative;
	z-index: 3;

	@include bp("< 650px"){
		width: 70px;
	}

	@include bp("< 580px"){
		display: none;
	}
}


#nazev {
	text-align: center;
	position: relative;
	margin: 8px 0 -14px ratio(1280px, 30px);

	a {
		z-index: 30;
		display: inline;
		text-decoration: none !important;
		outline-width: 0;
		font-size: 0;

		img {
			font-size: rem(16px);
			line-height: 1;
		}

		&::after {
			content: "";
			@extend %fill;
		}
	}
}

#jazykyGte {
	float: right;
	margin: 28px ratio(1280px, 36px) 0 0;

	@include bp("< 1000px"){
		margin: 16px 2.2% 12px 50px;
	}
}

#logoBrno{
	float: right;
	margin: 26px ratio(1280px, 26px) 0 ratio(1280px, 34px);

	@include bp("> 600px", "< 850px"){
		position: absolute;
		top: 58px;
		right: 160px;
	}
}

@include bp("> 500px") {
	#znak,
	#nazev,
	#nazev a,
	h1.cvi {
		float: left;
	}

	#nazev {
		text-align: left;
	}
}


/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/

#obalobrazek{
	position: relative;
	display: block;
	clear: both;
	max-width: 1920px;
	margin: 0 auto;
}

#obrazek {
	display: none;
}

#obalobrazek {
	@include bp(">= 800px"){
		padding-bottom: ratio(1920px, 460px);
	}	
	
	@include bp("> 1920px"){
		padding-bottom: 460px;
	}
}

@include bp(">= 600px"){
	#obrazek_sp{
		background: url(images/bg_top_sp.jpg) no-repeat center top;
		padding-bottom: 300px;
		background-size: contain;

		@include bp("< 1920px"){
			padding-bottom: 15.625%;
		}
	}
}

@include bp("> 600px") {
	#obrazek{
		display: block;
		position: absolute;
		left: 0; top: 0; right: 0; bottom: 0;

		@include bp("< 800px"){
			position: static!important;
			padding-bottom: ratio(1920px, 460px);
		}
	}

	// #obrazek {
	// position: relative;
		
		// bez blenderu:
		// background: #303030 url(images/blenderImages/0.jpg##{$cache-hash}) no-repeat 50% 50%;
		// background-size: cover;

		// s blenderem místo toho následující:

		// #blender {
		// 	@include fill;
		//  z-index: 1;
		// 	overflow:hidden;
		//	background: #303030 url(images/blenderImages/0.jpg) no-repeat 0 0;
		// 	background-size: cover;

		// 	div,
		// 	span,
		// 	a {
		// 		@include fill;
		//		width: auto !important;
		//		height: auto !important;
		// 		background-size: cover !important;
		// 		overflow: hidden;
		// 	}

		// 	img {
		// 		position: absolute;
		// 		left: 0;
		// 		top: 0;
		// 		width: 100%;
		// 		height: auto;
		// 	}
		// }
	// }

	// #administraceobrazky {
	// 	position: absolute;
	// 	left: 0;
	// 	top: 0;
	//  z-index: 2
	// }

	// #obrlinky {
	// 	position: absolute;
	// 	z-index: 2;
	// 	bottom: 14px;
	// 	right: 25px;

	// 	ul {
	// 		margin: 0;
	// 		padding: 0;
	// 		list-style-type: none;

	// 		li {
	// 			display: inline;
	// 			padding: 0;
	// 			margin: 0 0 0 14px;
	// 		}
	// 	}

	// 	a {
	// 		display: inline-block;
	// 		font-size: 0;
	// 		line-height: 0;
	// 		width: 17px;
	// 		height: 17px;
	// 		border-radius: 100px;
	// 		background-color: #ffffff;
	// 		box-shadow: 1px 1px 0 rgba(#000000, .85) inset;

	// 		&.imagesBlender_link_active {
	// 			background-color: red;
	// 		}
	// 	}
	// }
}

#obaljazyky{
	display: inline-block;
	margin: 0;

	ul{
		margin: 0;
		padding:0;
	}
	
	span{
		font-weight: normal;
	}

	a{
		text-decoration: underline;
		font-family: $font;

		&:hover{
			text-decoration: none;
		}

	}

	li {
		margin: 0 7px;
		display: inline-block;
	}
}

#jazyky {
	.goog-te-menu2-item div,
	.goog-te-menu2-item:link div,
	.goog-te-menu2-item:visited div,
	.goog-te-menu2-item:active div {
		background-color: transparent;
	}

	.goog-te-menu2-item:hover div {
		background-color: transparent;
	}

	.goog-te-menu2-item-selected div,
	.goog-te-menu2-item-selected:link div,
	.goog-te-menu2-item-selected:visited div,
	.goog-te-menu2-item-selected:hover div,
	.goog-te-menu2-item-selected:active div {
		font-weight: normal;
	}

	a > div {
		padding: 0;

		.indicator {
			display: none;
		}
	}

	.text {
		font-size: 0;

		&::before {
			font-size: rem(20px);
			font-weight: 600;
			color: #2a2a2a;
		}

		&.cs::before {
			content: "CS";
		}

		&.en::before {
			content: "EN";
		}

		&.de::before {
			content: "DE";
		}
	}
	
	div {
		display: inline;
	}
}

#lista {
	@extend %sf;
	text-align: right;
	padding: 6px 0 2px 12px;
	position: relative;

	ul {
		@extend %reset-list;
		position: relative;

		&.inline {
			display: inline;
		}
	}

	li {
		margin: 0;
		white-space: nowrap;

		&.skip {
			position: absolute;
			display: block;
			top: 0 !important;
			right: 0 !important;
			max-width: 100%;
			padding: 1px;
			border: 0;

			a {
				text-align: center;
				border: 0;
				position: absolute;
				right: 0;
				top: -500px;
				z-index: 0;
				padding: 0 2px;
				background-color: #ffffff;
				color: #000000;
			}
			
			a:focus,
			a:active {
				z-index: 10 !important;
				position: absolute !important;
				right: 10px;
				top: 5px;
			}
		}
	}
}
