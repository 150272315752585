// Sablona - vsechny stranky

/**************************
* DEFINICE PROMĚNNÝCH
**************************/

$max-width: 1280px;

$link-focus-bg: #303030;
$link-focus-text: #ffffff;

/*************************/

/**************************
* OBECNÉ
**************************/

html {
	touch-action: manipulation;
}

html,
body {
	background-color: #ffffff;
}

body {
	@extend %reset;
	text-align: center;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.expandable):not(
		.menu-link
	):not(.mouse-focus):focus {
	border-radius: 3px;
	box-shadow: 0 0 0 2000px $link-focus-bg inset, 0 0 0 3px $link-focus-bg !important;
	color: $link-focus-text !important;
	outline: 0;
}

/*************************
* HLAVNÍ OBSAH
*************************/

.centrovany {
	@extend %sf;
	width: 94%;
	max-width: $max-width;
	margin: 0 auto;
	text-align: left;
}

.centCelek {
	max-width: 1212px;
}

#hlava {
	width: 100%;
}

#hlobsah,
#navigace,
#navigace2 {
	display: block;
}

#obalcelek {
	max-width: 1920px;
	margin: 0 auto;
	overflow: hidden;
	background-color: #eff0eb;

	#stranka & {
		background-color: #ffffff;

		#celek {
			width: 100%;
		}
	}
}

#stred,
#vpravo,
#vlevo {
	@extend %border-box;
	width: 100%;
	float: none;
	margin-bottom: 30px;
}

#vpravo,
#vlevo {
	word-wrap: break-word;
}

#stranka .editor,
.perex {
	font-family: $font2;
	color: #4f4f4f;
	font-size: rem(17px);
}

#stranka #stred {
	padding: 15px 5% 15px 3.9%;

	@include bp("< 800px") {
		padding: 15px 4%;
	}
}

/*************************************
* TABULKOVÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/

// @include bp("> 650px") {
// 	#telo {
// 		display: table;
// 		table-layout: fixed;
// 		border-spacing: 0;
//			width: 100%;

// 		#stred,
// 		#vpravo,
// 		#vlevo {
// 			display: table-cell;
// 			vertical-align: top;
// 		}

// 		#vlevo {
// 			width: 300px;
// 		}

// 		#vpravo {
// 			width: 300px;
// 		}

// 		#stranka & {
// 			direction: rtl;

// 			> * {
// 				direction: ltr;
// 			}
// 		}
// 	}
// }

/*************************************
* FLOATOVANÝ LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/

#telo,
#stred {
	@extend %sf;
}

@include bp("> 800px") {
	#stred {
		float: left;
		width: ratio($max-width, 860px);

		#stranka & {
			float: right;
			background-color: #ffffff;
		}
	}

	#vpravo,
	#vlevo {
		width: ratio($max-width, 412px);
	}

	#vpravo {
		float: right;
	}

	#vlevo {
		float: left;
	}
}

/************************
* PRVKY STRÁNKY
************************/

#obalCesta {
	background-color: #f3f3ef;

	.centrovany {
		max-width: 1234px;
		margin: 0 auto;
		width: 92%;
	}
}

.cesta {
	font-size: rem(15px);
	padding: 19px 0 18px;
	margin: 0;
	position: relative;
	font-family: $font2;
	color: #444444;

	strong {
		font-weight: normal;
	}
}

.box {
	padding: 0 15px 10px 15px;
	background: #cccccc;
	margin: 0 0 15px 0;
}

.obaldalsi {
	text-align: center;
	margin: 16px 0 32px;

	.dalsi {
		display: inline-block;
		position: relative;
		height: 2.45em;
		font-size: rem(20px);
		background-color: $barZ;
		text-align: center;
		border-radius: 3px;
		box-sizing: border-box;
		border: 2px solid $barZ;
		width: 8em;

		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			transition: height 0.5s;
			height: 0;
			background-color: white;
			z-index: 2;
			border-radius: 3px;
		}

		&:hover::after {
			height: 100%;
		}

		a {
			font-size: rem(20px);
			text-transform: uppercase;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 3;
			color: #2a2a2a;
			font-family: $font;
			font-weight: 600;
			padding: 10px 16px;
			box-sizing: border-box;
		}

		@include link {
			text-decoration: none;
		}

		@include link-over {
			text-decoration: underline;
		}
	}
}

/************************
* ZÁPATÍ
************************/

#nadpatou {
	background: $barG;
	border-top: 8px solid $barR;
	padding: 50px 0 40px;

	h2.cvi {
		color: $barZ;
		font-size: rem(35px);
		font-family: $font;
		text-align: center;
		padding: 0 0 32px;
		margin: 0;
		font-weight: 600;
	}

	@include bp("<= 600px") {
		padding: 30px 0 40px;

		h2.cvi {
			padding: 0 0 10px;
		}
	}

	&,
	h3.cvi,
	#{selector-link()} {
		color: #ffffff;
	}

	.boxNp {
		@include grid(601px 2, 900px 3);
		padding: 0 4%;
		box-sizing: border-box;
	}

	#napiste {
		@include bp("> 600px") {
			border-left: 1px solid #717680;
		}

		@include bp(">= 900px") {
			border-right: 1px solid #717680;
		}
	}

	#abo {
		@include bp("> 600px", "< 900px") {
			margin-top: -200px;
		}
	}
}

$barvaPismaPaty: #444444; // todo

#obalpata {
	background-color: #eff0eb; // todo
	min-width: 320px;
}

#pata {
	text-align: center;
	padding: 22px 0 28px;
	color: $barvaPismaPaty;
	box-sizing: border-box;
	font-size: rem(14px);

	* {
		line-height: 2.2;
		border: 0;
	}

	li + li::before {
		content: "\|";
		margin: 0 8px 0 0;
	}

	.partWebmaster1::before {
		content: "\|";
		margin: 0 10px 0 0;
	}

	@include link {
		color: $barvaPismaPaty;
		text-decoration: underline;
	}

	@include link-over {
		text-decoration: none;
	}

	.patalogo {
		padding: 1px 6px;
		margin-right: 11px;
	}

	#pocitadlo {
		margin: 0 !important;
		padding-bottom: 0 !important;
	}

	.webmaster,
	.inline {
		display: inline-block;
		margin: 0;
		padding: 0 0 0 3px;

		li {
			padding: 0 5px;

			a {
				margin: 0 0 0 1px;
			}
		}
	}

	.webmaster {
		display: block;

		#stranka & {
			margin-top: 2px;
		}

		.partWebmaster {
			display: inline-block;
			margin-left: 5px;
		}
	}

	ul.inline li {
		display: inline-block;
	}
}

#obalmbannery {
	min-width: 320px;
}

@include bp("< 600px") {
	#pocitadlo {
		li {
			display: block !important;

			+ li::before {
				content: "";
				margin: 0;
			}
		}
	}
}

@include bp("< 500px") {
	.partWebmaster1 {
		display: block !important;
		margin-top: 2px;

		&::before {
			content: "" !important;
			margin: 0 !important;
		}
	}
}

#unpobtekane {
	margin-left: 0 !important;
}

@if is-bit-set($web_bitmask, $BIT_SKRYT_KONTEXT) {
	.zobrazeno.kontext2 {
		display: none;
	}
}

@if is-bit-set($web_bitmask, $BIT_SKRYT_ZODPOVIDA) {
	.popis.dpopis .zodpovida,
	.popis.dpopis .zodpovida + br {
		display: none;
	}
}

.noleftpanel .vol-sdileni {
	margin-top: -10px;
	margin-bottom: 40px;
}

h3.cvi.souvisejiciodkazy {
	display: none;
}
