
/*************************
* ŠABLONY
*************************/


/*************************
* DOKUMENTY
*************************/

%card {
   @include grid(500px 2 2, 1024px 3);
   box-sizing: border-box;
   padding: 0 20px 20px 20px;
   margin-top: 0;
   margin-bottom: 20px;
   background-color: #ffffff;
   overflow: hidden;

   img {
      float: none;
      display: table;
      margin: 0;
   }

   .dok-upoutavka {
      display: block;
      overflow: hidden;
      margin: 0 -20px;
   }

   .dok-nazev {
      padding-top: 10px;
      display: block;
   }

   div {
      margin-top: 8px;
   }
}


%card-horizontal {
   @include grid(800px 2 2);
   box-sizing: border-box;
   background-color: #ffffff;
   padding: 15px 20px;
   margin-top: 0;

   &.u {
      padding-left: 150px;
   }

   img {
      float: left;
      margin: -15px 0 0 -150px;
      max-width: 130px;

      @include bp("< 420px") {
         float: none;
         display: table;
         margin: 0 0 10px 0;
      }
   }
}